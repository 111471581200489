import React from 'react';
import { Subject } from 'rxjs';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { LinearProgress } from '@mui/material';
import { useAdminListOperationsForRunAllQuery } from '../../../generated/graphql';
import { Operation } from './Operation';
import { SectionCardHeader } from '../../common/SectionCardHeader';
import ErrorMessage from '../../common/ErrorMessage';

interface Props {
  callAll: Subject<null>;
  incrementSuccessful: () => void;
  incrementErrors: () => void;
  totalExecutionTime: (latestExecTime: number) => void;
  setTotalOperations: (totalOperations: number) => void;
  stressRuns?: number;
}

export function RunOperationsTable(props: Props) {
  const {
    data, refetch, error, loading,
  } = useAdminListOperationsForRunAllQuery();

  const applications = data?.user.applications
    .filter((a) => a.operations.length > 0); // Filter out applications without operations

  const totalOperations = applications?.reduce((total, application) => {
    // Filter out operations marked as draft
    const filteredOperations = application.operations.filter((operation) => !operation.draft);
    return total + filteredOperations.length;
  }, 0);

  props.setTotalOperations(totalOperations || 0);

  return (
    <Box>
      {loading && <LinearProgress />}
      <ErrorMessage error={error} />
      {applications?.map((application) => (
        <Box key={application.appId} mt={2}>
          <Card>
            <SectionCardHeader
              title={(
                <Stack direction='row' spacing={2}>
                  <span>{application.teamName}</span>
                  <strong>{application.appName}</strong>
                  <span>{application.appId}</span>
                </Stack>
              )}
            />
          </Card>
          {application.operations.map((operation) => (
            <Operation
              operation={operation}
              application={application}
              refetch={refetch}
              key={operation.id}
              callAll={props.callAll}
              incrementSuccessful={props.incrementSuccessful}
              incrementErrors={props.incrementErrors}
              totalExecutionTime={props.totalExecutionTime}
              stressRuns={props.stressRuns}
            />
          ))}
        </Box>
      ))}
    </Box>
  );
}
