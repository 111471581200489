import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import React, { PropsWithChildren } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { colors } from '../../../../../../services/mui-theme';
import {
  CpvSetValuesFragment,
} from '../../../../../../generated/graphql';
import { ToggleAltCodes } from '../../configuration-dictionaries/ToggleAltCodes';

interface Props {
  title: string;
  values: CpvSetValuesFragment[];
}

export function CpvSetCpvTable(props: Props) {
  const { values } = props;
  // State for swatch
  const [showAlt, setShowAlt] = React.useState<boolean>(true);

  return (
    <>
      <Stack direction={'row'} spacing={2} mt={2} ml={2}>
        <Typography
          variant="h3"
          sx={{ flexGrow: 1, m: 1 }}>
          {props.title}
        </Typography>
        <ToggleAltCodes checked={showAlt} setVisibility={setShowAlt} />
      </Stack>
      <Paper variant={'outlined'}>
        <TableContainer>
          <Table
            size="small"
            sx={{
              'td, th': { verticalAlign: 'top' },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  cd.name
                </TableCell>
                <TableCell>
                  cp.code {showAlt && <Alt>cp.altCode</Alt>}
                </TableCell>
                <TableCell>
                  cp.name {showAlt && <Alt>cp.altName</Alt>}
                </TableCell>
                <TableCell>
                  cp.description &
                  <Alt>cp.marketDescription</Alt>
                </TableCell>
                <TableCell>
                  code {showAlt && <Alt>altCode</Alt>}
                </TableCell>
                <TableCell>
                  name {showAlt && <Alt>altName</Alt>}
                </TableCell>
                <TableCell>
                  description &
                  <Alt>marketDescription</Alt>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[...values].sort(sortCpvs).map((value, index) => (
                <TableRow
                  key={index}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell>
                    {value.configurationParameter?.configurationDictionary?.name}
                  </TableCell>
                  <TableCell sx={{ background: colors.lightUi.light4 }}>
                    {value.configurationParameter?.code}
                    {showAlt && <Alt>{value.configurationParameter?.altCode}</Alt>}
                  </TableCell>
                  <TableCell sx={{ background: colors.lightUi.light4 }}>
                    {value.configurationParameter?.name}
                    {showAlt && <Alt>{value.configurationParameter?.altName}</Alt>}
                  </TableCell>
                  <TableCell sx={{ background: colors.lightUi.light4 }}>
                    {value.configurationParameter?.description}
                    <Alt>{value.configurationParameter?.marketDescription}</Alt>
                  </TableCell>
                  <TableCell>
                    {value.code}
                    {showAlt && <Alt>{value.altCode}</Alt>}
                  </TableCell>
                  <TableCell>
                    {value.name}
                    {showAlt && <Alt>{value.altName}</Alt>}
                  </TableCell>
                  <TableCell>
                    {value.description}
                    <Alt>{value.marketDescription}</Alt>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}

function sortCpvs(a: CpvSetValuesFragment, b: CpvSetValuesFragment) {
  if (a.configurationParameter.code < b.configurationParameter.code) {
    return -1;
  }
  if (a.configurationParameter.code > b.configurationParameter.code) {
    return 1;
  }

  return 0;
}

function Alt(props: PropsWithChildren) {
  return (
    <Box sx={{ color: colors.brand.orange2 }}>
      {props.children}
    </Box>
  );
}
