import React from 'react';
import Box from '@mui/material/Box';

/**
 * Returns the relative time since the last modified date.
 * shows minutes if less than 2 hour,
 * hours if less than 2 day,
 * days if less than 2 months,
 * months if less than 2 year,
 * years if more than 2 year.
 *
 * example input: 2025-01-28T12:43:36.295Z
 */
export function LastModifiedRelativeTime(props: { lastModified: string }) {
  const date = new Date(props.lastModified);
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  const seconds = diff / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const days = hours / 24;
  const months = days / 30;
  const years = days / 365;

  if (minutes < 60 * 2) {
    return <Box component="span" sx={{ color: 'success.main' }}>{Math.round(minutes)} minutes ago</Box>;
  }
  if (hours < 24 * 2) {
    return <Box component="span" sx={{ color: 'success.main' }}>{Math.round(hours)} hours ago</Box>;
  }
  if (days < 30 * 2) {
    return <Box component="span" sx={{ color: 'text.primary' }}>{Math.round(days)} days ago</Box>;
  }
  if (months < 12 * 2) {
    return <Box component="span" sx={{ color: 'text.secondary' }}>{Math.round(months)} months ago</Box>;
  }
  return <Box component="span" sx={{ color: 'text.disabled' }}>{Math.round(years)} years ago</Box>;
}
