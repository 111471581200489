import { GraphiQLProvider, QueryEditor, VariableEditor } from '@graphiql/react';
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import '@graphiql/react/dist/style.css';
import './graphiql.css';
import { useFetcher } from './useFetcher';

interface Props {
  initialQuery: string;
  initialVariables: string;
  onEdit: (value: string) => void;
  onVariablesEdit: (value: string) => void;
}

export function GraphQLEditor(props: Props) {
  const fetcher = useFetcher();

  return (
    <GraphiQLProvider
      fetcher={fetcher}
      query={props.initialQuery}
      variables={props.initialVariables}
    >
      <div className="graphiql-container">
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'stretch',
          minHeight: lineCount(props.initialQuery) * 22.5 + 10,
          '& > *': { flex: 1 },
        }}>
          <QueryEditor onEdit={props.onEdit}/>
        </Box>
        {props.initialVariables && (
          <>
            <Typography
              variant="subtitle2"
              sx={{
                borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                m: 0,
                pt: 0.2,
                pl: 0.5,
                fontSize: 12,
              }}
            >Variables:</Typography>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'stretch',
              minHeight: lineCount(props.initialVariables) * 22.5 + 10,
              '& > *': { flex: 1 },
            }}>
              <VariableEditor onEdit={props.onVariablesEdit}/>
            </Box>
          </>
        )}
      </div>
    </GraphiQLProvider>
  );
}

/**
 * Calculate the number of lines in a string. The minimum number of lines is 4.
 */
function lineCount(s: string) {
  return Math.max(s.split(/\r\n|\r|\n/).length, 3);
}
