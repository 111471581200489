import React from 'react';
import {
  FormControl, InputLabel, LinearProgress, MenuItem, Select, SelectChangeEvent,
} from '@mui/material';
import ErrorMessage from '../../../../../common/ErrorMessage';
import { useBrowsePlantCodeQuery } from '../../../../../../generated/graphql';

export function PlantCodeSelect(props: { onSelect: (value: number) => void }) {
  const { data, loading, error } = useBrowsePlantCodeQuery();
  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (loading) {
    return <LinearProgress/>;
  }

  if (!data) {
    return null;
  }

  return (
      <FormControl fullWidth>
        <InputLabel>Plant Code</InputLabel>
        <Select
          label="PlantCode"
          onChange=
          {(event: SelectChangeEvent<number>) => props.onSelect(event.target.value as number)}
          defaultValue={'' as unknown as number}
          // Empty string is needed to make it a controlled component.
          // 0 will not work as it will look for the value in the list
        >
          {data.plants.map((b, i) => (
            <MenuItem key={i} value={b.plantCode}>
              <span className="font-monospace">{b.plantCode}</span>&nbsp;- {b.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
  );
}
