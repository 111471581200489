import Paper from '@mui/material/Paper';
import React, { PropsWithChildren } from 'react';
import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Tab from '@mui/material/Tab';
import { StructureWeekInput } from '../../documentation/pages/browser/common/StructureWeekInput';
import { FeatureBuilderLegacyFormat } from './FeatureBuilderLegacyFormat';
import { PlantCodeInput } from '../../documentation/pages/browser/common/PlantCodeInput';

interface Props {
  values: Input;
  onChange: (v: Partial<Input>) => void;
}

export interface Input {
  structureWeek: number;
  typeCode: string;
  features: string[];
  plantCode: number;
}

export function PartLabelBreakdownForm(props: Props) {
  const [tab, setTab] = React.useState('text');

  return (
    <>
      <Paper variant="outlined" sx={{ mb: 1 }}>
        <FakeTableHead>StructureWeek</FakeTableHead>
        <FakeTableCell>
          <StructureWeekInput
            defaultValue={props.values.structureWeek}
            onChange={(v) => props.onChange({
              structureWeek: v,
            })}
          />
        </FakeTableCell>
      </Paper>
      <Paper variant="outlined" sx={{ mb: 1 }}>
        <FakeTableHead>TypeCode</FakeTableHead>
        <FakeTableCell>
          <TextField
            label=""
            variant="standard"
            placeholder={'0000'}
            className="font-monospace"
            defaultValue={props.values.typeCode}
            onChange={(e) => props.onChange({
              typeCode: e.target.value as string,
            })}
          />
        </FakeTableCell>
      </Paper>
      <Paper variant="outlined" sx={{ mb: 1 }}>
        <FakeTableHead>PlantCode</FakeTableHead>
        <FakeTableCell>
          <PlantCodeInput
            defaultValue={props.values.plantCode}
            onChange={(v) => props.onChange({
              plantCode: v,
            })}
          />
        </FakeTableCell>
      </Paper>
      <Paper variant="outlined">
        <FakeTableHead>Features</FakeTableHead>
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={(e, v) => setTab(v)}>
              <Tab label="Text" value="text"/>
              <Tab label="Feature builder" value="select"/>
            </TabList>
          </Box>
          <TabPanel value="text" sx={{ px: 2, py: 1 }}>
            <FeaturesInput
              value={props.values.features.join('\n')}
              onChange={(v) => props.onChange({
                features: v,
              })}
            />
          </TabPanel>
          <TabPanel value="select" sx={{ px: 2, py: 1 }}>
            <FeatureBuilderLegacyFormat
              typeCode={props.values.typeCode}
              value={props.values.features.join('\n')}
              onChange={(v) => props.onChange({
                features: v,
              })}
            />
          </TabPanel>
        </TabContext>

      </Paper>
    </>
  );
}

function FeaturesInput(props: { value: string, onChange: (v: string[]) => void }) {
  const onChange = (newValue: string) => {
    props.onChange(
      newValue
        .replace(/\n/g, ',')
        .replace(/&/g, ',')
        .split(',')
        .map((v) => v.trim())
        .filter((v) => v.length > 0),
    );
  };

  return (
    <TextField
      className="font-monospace"
      label=""
      variant="standard"
      defaultValue={props.value}
      placeholder={'AA01\nYV06\n4A03'}
      onChange={(e) => onChange(e.target.value)}
      multiline
      minRows={40}
    />
  );
}

/**
 * Looks like a table head, to match similar designs
 */
function FakeTableHead(props: PropsWithChildren) {
  return (
    <Box sx={{
      lineHeight: '1.5rem',
      fontFamily: "'Volvo Novum 2',Arial,sans-serif",
      fontSize: '0.875rem',
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
      px: 2,
      py: 1,
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: '600',
    }}>
      {props.children}
    </Box>
  );
}

/**
 * Looks like a table cell, to match similar designs
 */
function FakeTableCell(props: PropsWithChildren) {
  return (
    <Box sx={{
      px: 2,
      py: 1,
    }}>
      {props.children}
    </Box>
  );
}
