export const importers = [
  {
    name: 'Upper BOM Import',
    trigger: 'file upload (1/day)',
    usesBullMq: true,
    sourceType: 'plmxml',
    sourceSystem: 'Teamcenter PLM',
    models: [
      'BomInstance',
      'Art',
      'Function Group',
      'FgPos',
      // todo: Revoke of PartAddress when FgPos is revoked
    ],
    events: [],
  },
  {
    name: 'Lower BOM Import',
    trigger: 'file upload (when released)',
    usesBullMq: true,
    sourceType: 'plmxml',
    sourceSystem: 'Teamcenter PLM',
    models: [
      'Part',
      'PartVersion',
      'PartUsage',
      'PartConstituent',
      'ChangeOrder',
      'ChangeOrderIssue',
      'PartFolderIssue',
      'SoftwarePart',
      'DocumentRevision',
      'Plant',
      'DeliveryModule',
      'DeliveryModuleConstituent',
      'PartAddress',
      'ConsumptionRecord',
      'MBOM',
      'UsageRule (Without PML ID)',
      'FgPos, Art, Function Group (for LMOD/DMOD and constituents)',
    ],
    events: ['ReleasedBomDataUpdated'],
  },
  {
    // Only when new fields are introduced.
    name: 'Part Version Bulk Import',
    trigger: 'file upload (manual trigger)',
    usesBullMq: true,
    sourceType: 'plmxml',
    sourceSystem: 'Teamcenter PLM',
    models: [
      'PartVersion (Update only)',
    ],
    events: ['ReleasedBomDataUpdated'],
  },
  {
    name: 'Lower BOM Assignments Import',
    trigger: 'file upload (when released)',
    usesBullMq: true,
    sourceType: 'plmxml',
    sourceSystem: 'Teamcenter PLM',
    models: [
      'Part (update only)',
      'SoftwarePart (update only)',
    ],
    events: [],
  },
  {
    name: 'BOP Manufacturing Import',
    trigger: 'file upload (when released)',
    usesBullMq: true,
    sourceType: 'plmxml',
    sourceSystem: 'Teamcenter MPLM',
    models: [
      'MfgAssemblyPlaceholder',
      'MfgAssembly',
      'MfgPart',
      'MfgChangeOrder',
      'MfgChangeOrderIssue',
      'MfgPartConstituent',
      'MfgPartUsage',
      'BopRouting',
      'BopSubRouting',
      'BopCompoundOperation',
      'BopOperation',
      'BopOperationParameter',
      'BopMfgAssemblyTarget',
      'BopPartUsage',
      'Part (Just in case it is not already in the system)',
    ],
    events: ['MfgChangeOrderIssueUpdated'],
  },
  {
    name: 'Spare Part Info Import',
    trigger: 'file upload (when released)',
    usesBullMq: true,
    sourceType: 'plmxml',
    sourceSystem: 'Teamcenter PLM',
    models: [
      'SparePartInfo',
    ],
    events: ['SparePartInfoUpdated'],
  },
  {
    name: 'Software Baseline Import',
    trigger: 'file upload (when released)',
    usesBullMq: true,
    sourceType: 'plmxml',
    sourceSystem: 'Teamcenter PLM',
    models: [
      'SwBomInstance',
      'SwCompleteBaseline',
      'SwDeploymentContext',
      'SwExecutionEnvironment',
      'SwBaselineHWPartUsage',
      'SwBaselineSWPartUsage',
      'UsageRule (Without PML ID)',
    ],
    events: ['SwBaselineUpdated'],
  },
  {
    name: 'Pre-Series CSV Import',
    trigger: 'file upload (automatic 1/day)',
    usesBullMq: true,
    sourceType: 'csv',
    sourceSystem: 'Teamcenter PLM',
    models: [
      'TestSeriesConsumption',
      'UsageRule (Without PML ID)',
      'ProjectBuildEvent (update only)',
    ],
    dependecies: [
      'Project Build Event Import',
    ],
    events: [],
  },
  {
    name: 'Project Build Event Import',
    trigger: 'cron (automatic 1/h)',
    usesBullMq: true,
    sourceType: 'api (json)',
    sourceSystem: 'TOCC / TOS',
    models: [
      'ProjectBuildEvent',
    ],
    events: [],
  },
  {
    name: 'Label KDP Data Import',
    trigger: 'azure blob trigger',
    usesBullMq: true,
    sourceType: 'csv',
    sourceSystem: 'KDP (Through ICOE)',
    models: [
      'LabelTextParameter',
      'LabelTextPosition',
      'LabelDefinition',
    ],
    events: [],
  },
  {
    name: 'COMO Import',
    trigger: 'cron (automatic 1/hour)',
    usesBullMq: false,
    sourceType: 'api (json)',
    sourceSystem: 'COMO',
    models: [
      'ConfigurationDictionary',
      'VariabilityDomain',
      'ConfigurationParameter',
      'ConfigurationParameterValue',
    ],
    events: [
      // Todo: Should we not trigger ConfigurationParametersUpdated?
    ],
  },
  {
    name: 'ACE Import',
    trigger: 'azure blob trigger',
    usesBullMq: false,
    sourceType: 'csv',
    sourceSystem: 'ACE / KDP (Through ICOE)',
    models: [
      'UsageRule',
      'ConfigurationParameter',
      'ConfigurationParameterValue',
    ],
    events: [
      // Todo: Should we not trigger ConfigurationParametersUpdated?
    ],
  },
  {
    name: 'PNO Translator PML Import',
    trigger: 'cron',
    usesBullMq: false,
    sourceType: 'API',
    sourceSystem: 'PNO System',
    models: [
      'UsageRule, including PML ID and Plant authorizations',
    ],
    events: [],
  },
  {
    name: 'Stored Cpv Set Import',
    trigger: 'blob trigger',
    usesBullMq: true,
    sourceType: 'ndjson',
    sourceSystem: 'Teamcenter Configuration',
    models: [
      'StoredCpvSet',
    ],
    events: [
      'StoredCpvSetImportStart (before import)',
      'StoredCpvSetDataUpdated',
    ],
  },
  {
    name: 'Configuration Importer (wip)',
    trigger: 'blob trigger',
    usesBullMq: true,
    sourceType: 'ndjson',
    sourceSystem: 'Teamcenter Configuration',
    models: [
      'ConfigurationParameter',
      'ConfigurationParameterValue',
      'ConfigurationRule',
      'ConfigurationRuleDictionary',
      'CommercialPackage',
      'ModelYear',
    ],
    events: [
      'ConfigurationParametersUpdated',
      'ConfigurationRuleUpdated',
    ],
  },
  {
    name: 'Teamcenter Configuration script (deprecated)',
    trigger: '-',
    usesBullMq: false,
    sourceType: 'graphql',
    sourceSystem: 'Teamcenter Configuration',
    models: [
      'ConfigurationParameter',
      'ConfigurationParameterValue',
      'ConfigurationRule',
      'ConfigurationRuleDictionary',
      'CommercialPackage',
      'ModelYear',
    ],
    events: [
      'ConfigurationParametersUpdated',
      'ConfigurationRuleUpdated',
    ],
  },
  {
    name: 'Generic Part Importer (wip)',
    trigger: 'upload (when released)',
    usesBullMq: true,
    sourceType: 'tcxml',
    sourceSystem: 'Teamcenter PLM',
    models: [
      'GenericPart',
    ],
    events: ['GenericPartUpdated'],
  },
  {
    name: 'Manual updates',
    trigger: '',
    usesBullMq: false,
    sourceType: '',
    sourceSystem: '',
    models: [
      'Product Context',
    ],
    events: [],
  },
];

export const internalImporters = [
  {
    name: 'Structure Check SVC Import',
    trigger: 'file upload',
    usesBullMq: true,
    sourceType: 'ndjson',
    sourceSystem: 'Teamcenter PLM',
    models: [
      'StructureCheckSvc (Not visible for downstreams)',
    ],
    events: [],
  },
  {
    name: 'Structure Check Import',
    trigger: 'azure blob trigger',
    usesBullMq: true,
    sourceType: 'plmxml',
    sourceSystem: 'Teamcenter PLM',
    models: [
      'ExpectedQuantity (Not visible for downstreams)',
    ],
    events: [],
  },
];

export const otherImports = [
  {
    name: 'Data Quality check',
    trigger: 'file upload (automatic 1/day)',
    usesBullMq: false,
    sourceType: 'csv',
    sourceSystem: 'Teamcenter PLM',
    description: 'Compares data uploaded from TC with the data in PRINS and reports any discrepancies',

  },
  {
    name: 'Structure check report',
    trigger: 'cron (1/day)',
    usesBullMq: false,
    sourceType: '',
    sourceSystem: '',
    description: 'Sends a report to the stakeholders with the structure check results',

  },
  {
    name: 'Data cleansing',
    trigger: 'cron (1/week)',
    usesBullMq: true,
    sourceType: '',
    sourceSystem: '',
    description: 'See Data cleansing page for more info',
  },
];
