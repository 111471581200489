import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import React from 'react';
import Box from '@mui/material/Box';

interface ImportersData {
  name: string;
  trigger: string;
  usesBullMq: boolean;
  sourceType: string;
  sourceSystem: string;
  models?: string[];
  events?: string[];
  description?: string;
}

export function ImportersTable(props: { importers: ImportersData[] }) {
  return (
    <Paper variant="outlined">
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Trigger</TableCell>
              <TableCell>UsesBullMq</TableCell>
              <TableCell>SourceType</TableCell>
              <TableCell>SourceSystem</TableCell>
              <TableCell>Models</TableCell>
              <TableCell>Events</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.importers?.map((row, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.trigger}</TableCell>
                <TableCell>{row.usesBullMq ? 'Yes' : 'No'}</TableCell>
                <TableCell>{row.sourceType}</TableCell>
                <TableCell>{row.sourceSystem}</TableCell>
                <TableCell>
                  <Box component="ul" sx={{ p: 0, m: 0 }}>
                    {row.models?.map((model, index2) => (
                      <li key={index2}>{model}</li>
                    ))}
                  </Box>
                </TableCell>
                <TableCell>
                  <Box component="ul" sx={{ p: 0, m: 0 }}>
                    {row.events?.map((model, index2) => (
                      <li key={index2}>{model}</li>
                    ))}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export function ExtrasTable(props: { importers: ImportersData[] }) {
  return (
    <Paper variant="outlined">
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Trigger</TableCell>
              <TableCell>UsesBullMq</TableCell>
              <TableCell>SourceType</TableCell>
              <TableCell>SourceSystem</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.importers?.map((row, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.trigger}</TableCell>
                <TableCell>{row.usesBullMq ? 'Yes' : 'No'}</TableCell>
                <TableCell>{row.sourceType}</TableCell>
                <TableCell>{row.sourceSystem}</TableCell>
                <TableCell>{row.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
