import React, { useState } from 'react';
import {
  Box,
  Dialog,
  Tab,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { ProjectBuildEventDialogDocument, useProjectBuildEventDialogQuery } from '../../../../../generated/graphql';
import ErrorMessage from '../../../../common/ErrorMessage';
import { PrettyQuery } from '../../../../common/data/PrettyQuery';
import { ProjectBuildEventsDialogInfoPanel } from './ProjectBuildEventsDialogInfoPanel';
import { DialogLoader } from '../../../../common/dialog/DialogLoader';
import { DialogHeader } from '../../../../common/dialog/DialogHeader';
import { ProjectBuildEventBomTree } from './ProjectBuildEventBomTree';
import { ProjectBuildEventCompare } from './ProjectBuildEventCompare';

interface Props {
  id: string,
  close: () => void
}

export function ProjectBuildEventDialog(props: Props) {
  const [open, setOpen] = useState(true);
  const [tab, setTab] = useState('info');

  const { data, loading, error } = useProjectBuildEventDialogQuery({
    variables: {
      where: {
        id: props.id,
      },
    },
  });

  const projectBuildEvent = data?.projectBuildEvents[0];
  const bomInstances = data?.bomInstances;

  const handleClose = () => {
    setOpen(false);
    setTimeout(props.close, 1000);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth={true}
      maxWidth="xl"
      sx={{
        transform: 'translateZ(0)',
      }}
    >
      <DialogHeader
        type="Project Build Event"
        primaryText={projectBuildEvent?.event}
        secondaryText={projectBuildEvent?.project}
        onCloseClick={handleClose}
      />
      {loading && <DialogLoader />}
      <ErrorMessage error={error} />

      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={(e, v) => setTab(v)}>
            <Tab label="Info" value="info" />
            {/* <Tab label="Json" value="json" /> */}
            <Tab label="Compare" value="compare" />
            <Tab label="Example Query" value="query" />
          </TabList>
        </Box>
        <TabPanel value="info" sx={{ p: 0 }}>
          <ProjectBuildEventsDialogInfoPanel projectBuildEvent={projectBuildEvent} />
          {bomInstances && <ProjectBuildEventBomTree bomInstances={bomInstances} />}
        </TabPanel>
        {/* Json doesn't work well because the result is so large. */}
        {/* <TabPanel value="json"> */}
        {/*  {data && <PrettyJson data={data}/>} */}
        {/* </TabPanel> */}
        <TabPanel value="compare">
          {data?.projectBuildEvents[0] && (
            <ProjectBuildEventCompare projectBuildEvent={data.projectBuildEvents[0]} />
          )}
        </TabPanel>
        <TabPanel value="query">
          <PrettyQuery query={ProjectBuildEventDialogDocument.loc?.source.body.trim() || ''} />
        </TabPanel>
      </TabContext>
    </Dialog>
  );
}
