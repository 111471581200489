import React from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { DataTreeRow } from '../../../../common/data/DataTree';
import { BomNodeHeader } from '../bom/mbom/MbomInstancesTree';
import { ProjectBuildEventDialogQuery } from '../../../../../generated/graphql';
import { PbeTestSeriesConsumptionsTable } from './PbeTestSeriesConsumptionsTable';
import { colors } from '../../../../../services/mui-theme';
import { ReleaseStatusChip } from '../common/ReleaseStatusChip';

type BomInstance = ProjectBuildEventDialogQuery['bomInstances'];

interface Props {
  bomInstances: BomInstance
}

export function ProjectBuildEventBomTree(props: Props) {
  const { bomInstances } = props;

  if (!bomInstances.length) {
    return (
      <Alert severity="info">Not connected to any bom instances</Alert>
    );
  }

  return (
    <>
      {bomInstances.map((b, index) => (
        <DataTreeRow
          key={b.typeDesignation + index}
          header={`${b.typeDesignation} / ${b.typeCode}`}
          suffix={b.__typename}
          defaultExpanded={true}
        >
          {b.arts.map((art, index2) => (
            <DataTreeRow
              key={index2}
              header={<BomNodeHeader node={art} />}
              suffix={art.__typename}
              defaultExpanded={true}
            >
              {art.functionGroups.map((fg, index3) => (
                <DataTreeRow
                  key={index3}
                  header={<BomNodeHeader node={fg} />}
                  suffix={fg.__typename}
                  defaultExpanded={true}
                >
                  {fg.fgPos.map((pos, index4) => (
                    <DataTreeRow
                      key={index4}
                      header={<BomNodeHeader node={pos} />}
                      suffix={pos.__typename}
                      defaultExpanded={true}
                    >
                      {pos.partAddresses.map((pa, index5) => (
                        <DataTreeRow
                          key={index5}
                          header={(<PartAddressHeader
                              partAddress={pa}
                              consumptionRecordsCount={pa.testSeriesConsumption.length}
                            />
                          )}
                          suffix={pa.__typename}
                        >
                          <PbeTestSeriesConsumptionsTable rows={pa.testSeriesConsumption} />
                        </DataTreeRow>
                      ))}
                    </DataTreeRow>
                  ))}
                </DataTreeRow>
              ))}
            </DataTreeRow>
          ))}
        </DataTreeRow>
      ))}
    </>
  );
}

interface PartAddressHeaderProps {
  partAddress: {
    number: string;
    name: string;
    releaseStatus: string;
  };
  consumptionRecordsCount: number;
}

function PartAddressHeader(props: PartAddressHeaderProps) {
  const { partAddress, consumptionRecordsCount } = props;
  return (
    <Box display="flex" width="100%" alignItems="center">
      <Box color={colors.primary} pr={1}>{partAddress.number}</Box>
      <Box pr={1}>{partAddress.name}</Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box px={1}>
        Test Series Consumptions: {consumptionRecordsCount || 0}
      </Box>
      <ReleaseStatusChip releaseStatus={partAddress.releaseStatus} />
    </Box>
  );
}
