/* eslint-disable max-len */
import React, { useState } from 'react';

import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Tab from '@mui/material/Tab';
import { useWindowSize } from '@react-hook/window-size';
import ErrorMessage from '../../../../common/ErrorMessage';
import {
  MfgAssemblyPlaceholderDialogDocument,
  useMfgAssemblyPlaceholderDialogQuery,
} from '../../../../../generated/graphql';
import { GraphViewResult } from '../../../../common/GraphqlCodeBlock/GraphView/GraphView';
import { formatQueryResultForGraph } from '../../../../common/GraphqlCodeBlock/GraphView/graphview.util';
import { PrettyJson } from '../../../../common/data/PrettyJson';
import { PrettyQuery } from '../../../../common/data/PrettyQuery';
import { MfgAssemblyPlaceholderInfoPanel } from './MfgAssemblyPlaceholderDialogInfoPanel';
import { DialogLoader } from '../../../../common/dialog/DialogLoader';
import { DialogHeader } from '../../../../common/dialog/DialogHeader';
import { SlideUpTransition } from '../../../../common/dialog/SlideUpTransition';

interface Props {
  id: string,
  close: () => void
}

export function MfgAssemblyPlaceholderDialog(props: Props) {
  const [open, setOpen] = useState(true);
  const [tab, setTab] = React.useState('1');
  const [width, height] = useWindowSize();

  const { data, loading, error } = useMfgAssemblyPlaceholderDialogQuery({
    variables: {
      where: {
        id: props.id,
      },
    },
  });

  const entity = data?.mfgAssemblyPlaceholders[0];

  const handleClose = () => {
    setOpen(false);
    setTimeout(props.close, 1000);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth={true}
      maxWidth="xl"
      TransitionComponent={SlideUpTransition}
      sx={{
        transform: 'translateZ(0)',
      }}
    >
      <DialogHeader
        type="MfgAssemblyPlaceholder"
        primaryText={entity?.productId}
        secondaryText={entity?.name}
        onCloseClick={handleClose}
      />
      {loading && <DialogLoader/>}
      <ErrorMessage error={error} />

      <TabContext value={tab} >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={(e, v) => setTab(v)}>
            <Tab label="Info" value="1" />
            <Tab label="Graph" value="2" />
            <Tab label="Json" value="3" />
            <Tab label="Example Query" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ p: 0 }}>
          <MfgAssemblyPlaceholderInfoPanel mfgAssemblyPlaceholder={entity} />
        </TabPanel>
        <TabPanel value="2" sx={{ p: 0 }}>
          {data && <GraphViewResult
            graphData={formatQueryResultForGraph(data)}
            width={Math.min(width - 64, 1536)}
            height={height - 32 - 156}
            displayTypename
            dagMode
          />}
        </TabPanel>
        <TabPanel value="3">
          {data && <PrettyJson data={data} />}
        </TabPanel>
        <TabPanel value="4">
          <PrettyQuery query={MfgAssemblyPlaceholderDialogDocument.loc?.source.body.trim() || ''} />
        </TabPanel>
      </TabContext>

    </Dialog>
  );
}
