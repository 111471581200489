import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

interface TabPanelProps {
  tabs: {
    label: string,
    content: React.ReactNode,
    padding?: number,
  }[]
}

export function TabsPage(props: TabPanelProps) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ backgroundColor: 'background.paper', mt: 2 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {props.tabs.map(({ label }, i) => (
            <Tab key={i} label={label} value={i} />
          ))}
        </Tabs>
      </Box>
      {props.tabs.map(({ content, padding }, i) => (
        <div
          key={i}
          role="tabpanel"
          hidden={value !== i}
          id={`page-tabpanel-${i}`}
        >
          {value === i && <Box sx={{ p: padding }}>{content}</Box>}
        </div>
      ))}
    </Box>
  );
}
