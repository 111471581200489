import React from 'react';
import Card from '@mui/material/Card';
import {
  CardHeaderProps,
  LinearProgress,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import ErrorIcon from '@mui/icons-material/Error';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { SectionCardHeader } from '../common/SectionCardHeader';
import {
  useBullMqJobTableQuery,
} from '../../generated/graphql';
import ErrorMessage from '../common/ErrorMessage';
import { Section } from '../layout/Section';
import { CardRefreshAction } from '../common/CardRefreshAction';
import { BullMqJobTable } from './BullMqJobTable';
import TimelineChart, { Job } from './BullMqJobTimelineChart';

interface BullMqOverviewProps {
  jobNames: string[];
}
export function BullMqOverview(props: BullMqOverviewProps) {
  return (
    <Section maxWidth={2300}>
      <Jobs
        avatar={<PendingIcon color='primary' />}
        state={['active', 'prioritized', 'waiting', 'waiting-children', 'delayed']}
        jobNames={props.jobNames}
      />
      <Jobs
        avatar={<ErrorIcon color='error' />}
        state={['failed']}
        jobNames={props.jobNames}
      />
      <Jobs
        avatar={<CheckCircleIcon color='success' />}
        state={['completed']}
        jobNames={props.jobNames}
      />
    </Section>
  );
}

function Jobs(props: {
  state: string[],
  avatar: CardHeaderProps['avatar'],
  jobNames?: string[],
}) {
  const {
    data,
    loading,
    error,
    refetch,
  } = useBullMqJobTableQuery({
    variables: {
      states: props.state,
      jobNames: props.jobNames || [],
    },
  });

  const jobs = data?.user?.bullMqImportJobs || [];

  return (
    <Card sx={{
      mb: 2,
    }}>
      <SectionCardHeader
        avatar={props.avatar}
        title={`Jobs - ${props.state.join(', ')}`}
        action={<CardRefreshAction onClick={refetch} />}
      />
      <ErrorMessage error={error}></ErrorMessage>
      {loading && <LinearProgress></LinearProgress>}
      {jobs.length === 0 && !loading && (
        <Alert severity="info">No Jobs Found</Alert>
      )}
      {jobs.length > 0 && (
        <BullMqJobTable jobList={jobs}></BullMqJobTable>
      )}
      {jobs.length > 0 && (
        <Box sx={{
          mt: 2,
          width: '100%',
          overflowX: 'auto',
        }}>
          <TimelineChart jobs={jobs as Job[]}></TimelineChart>
        </Box>
      )}
    </Card>
  );
}
