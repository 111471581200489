/* eslint-disable max-len */
import React, { useState } from 'react';

import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Tab from '@mui/material/Tab';
import { DocumentNode } from 'graphql/language';
import ErrorMessage from '../../../../../common/ErrorMessage';
import {
  CpvSetDialogDocument,
  CpvSetDialogQuery,
  useCpvSetDialogQuery,
} from '../../../../../../generated/graphql';
import { FieldListValue } from '../../common/FieldListValue';
import { PrettyJson } from '../../../../../common/data/PrettyJson';
import { PrettyQuery } from '../../../../../common/data/PrettyQuery';
import { FeatureBreakdownTab } from './FeatureBreakdownTab';
import { DialogLoader } from '../../../../../common/dialog/DialogLoader';
import { QuantityQualityCheckTab } from './QuantityQualityCheckTab';
import { DialogHeader } from '../../../../../common/dialog/DialogHeader';
import { SlideUpTransition } from '../../../../../common/dialog/SlideUpTransition';
import { CpvSetCpvTable } from './CpvSetCpvTable';

interface Props {
  id: string,
  close: () => void
}
export function CpvSetDialog(props: Props) {
  const [open, setOpen] = useState(true);
  const [tab, setTab] = React.useState('preview');

  const { data, loading, error } = useCpvSetDialogQuery({
    variables: {
      where: {
        id: props.id,
      },
    },
  });

  const cpvSet = data?.storedCpvSets[0];

  const handleClose = () => {
    setOpen(false);
    setTimeout(props.close, 1000);
  };

  const features = data?.storedCpvSets[0].values.map((v) => {
    return `${v.configurationParameter.code} = ${v.code}`;
  }).sort();

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth={true}
      maxWidth="xl"
      TransitionComponent={SlideUpTransition}
      sx={{
        transform: 'translateZ(0)',
      }}
    >
      <DialogHeader
        type="Stored CPV Set"
        primaryText={cpvSet?.name}
        onCloseClick={handleClose}
      />
      {loading && <DialogLoader/>}
      <ErrorMessage error={error} />

      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={(e, v) => setTab(v)}>
            <Tab label="Preview" value="preview" />
            <Tab label="EBOM Breakdown" value="breakdown" />
            <Tab label="Quantity Check" value="qc" />
            <Tab label="Json" value="json" />
            <Tab label="Example Query" value="query" />
          </TabList>
        </Box>
        <TabPanel value="preview"><DataTab data={data}></DataTab></TabPanel>
        <TabPanel value="breakdown">
          <FeatureBreakdownTab
            typeCode={cpvSet?.modelYearDetails?.typeCode.toString() || ''}
            structureWeek={cpvSet?.effectiveInWeek || 0}
            features={features || []}
          ></FeatureBreakdownTab>
        </TabPanel>
        <TabPanel value="qc">
          <QuantityQualityCheckTab
            typeCode={cpvSet?.modelYearDetails?.typeCode.toString() || ''}
            features={features || []}
          ></QuantityQualityCheckTab>
        </TabPanel>
        <TabPanel value="json"><JsonTab data={data}></JsonTab></TabPanel>
        <TabPanel value="query"><QueryTab query={CpvSetDialogDocument}></QueryTab></TabPanel>
      </TabContext>
    </Dialog>
  );
}

function DataTab(props: { data?: CpvSetDialogQuery }) {
  const cpvSet = props.data?.storedCpvSets[0];

  return (
    <>
      <Box p={2}>
        <Paper variant="outlined">
          <List disablePadding>
            <FieldListValue name="name" value={cpvSet?.name} />
            <FieldListValue name="revision" value={cpvSet?.revision} />
            <FieldListValue name="pno" value={cpvSet?.pno} />
            <FieldListValue name="pno34" value={cpvSet?.pno34} />
            <FieldListValue name="externalPno" value={cpvSet?.externalPno} />
            <FieldListValue name="pno18" value={cpvSet?.pno18} />
            <FieldListValue name="description" value={cpvSet?.description} />
            <FieldListValue name="effectiveInWeek" value={cpvSet?.effectiveInWeek} />
            <FieldListValue name="effectiveOutWeek" value={cpvSet?.effectiveOutWeek} />
          </List>
        </Paper>
      </Box>

      <Box p={2}>
        <Typography variant="h3" mb={1} mx={2}>Model Year Details</Typography>
        <Paper variant="outlined">
          <List disablePadding>
            <FieldListValue name="type" value={cpvSet?.modelYearDetails?.type} />
            <FieldListValue name="typeCode" value={cpvSet?.modelYearDetails?.typeCode} />
            <FieldListValue name="year" value={cpvSet?.modelYearDetails?.year} />
          </List>
        </Paper>
      </Box>

      <Box p={2}>
        <CpvSetCpvTable
          title={'Spec Markets'}
          values={cpvSet?.specMarkets || []}
        />
      </Box>
      <Box p={2}>
        <CpvSetCpvTable
          title={'Values (Configuration Parameter Values)'}
          values={cpvSet?.values || []}
        />
      </Box>
    </>
  );
}

function JsonTab(props: { data?: CpvSetDialogQuery }) {
  if (!props.data) {
    return null;
  }

  return (
    <PrettyJson data={props.data} />
  );
}

function QueryTab(props: { query: DocumentNode }) {
  return (
    <>
      <PrettyQuery query={props.query.loc?.source.body.trim() || ''} />
    </>
  );
}
