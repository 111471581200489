import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ErrorMessage from '../../common/ErrorMessage';
import { usePartLabelBreakDownLazyQuery } from '../../../generated/graphql';
import { Input, PartLabelBreakdownForm } from './PartLabelBreakdownForm';
import { PartLabelBreakdownResultTable } from './PartLabelBreakdownResultTable';

interface Props {
  defaultValues: Input;
}
export function PartLabelBreakdown(props: Props) {
  const [input, setInput] = React.useState<Input>(props.defaultValues);

  const [call, {
    data, error, loading, variables,
    previousData,
  }] = usePartLabelBreakDownLazyQuery({});

  return (
    <>
      <Grid container spacing={1} wrap="nowrap">
        <Grid item sx={{ minWidth: 220 }}>
          <PartLabelBreakdownForm
            values={input}
            onChange={(v) => setInput({ ...input, ...v })}
          />
        </Grid>
        <Grid item sx={{ flexGrow: 1 }}>
          {!objectsAreEqual(input, variables) && (
            <Box p={3} textAlign="center">
              <LoadingButton variant="contained" onClick={() => call({
                variables: input,
              })} loading={loading}>
                Run PartLabel breakdown
              </LoadingButton>
            </Box>
          )}

          <Paper variant="outlined">
            {loading && <LinearProgress/>}
            <ErrorMessage error={error}></ErrorMessage>
            {data && (
              <PartLabelBreakdownResultTable
              partLabels={data.partLabelBreakDown.parts}
              />
            )}
            {(!data && previousData) && (
              <Box sx={{ opacity: 0.3 }}>
                <PartLabelBreakdownResultTable
                  partLabels={previousData.partLabelBreakDown.parts}
                />
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function objectsAreEqual(a: any, b: any) {
  return JSON.stringify(a) === JSON.stringify(b);
}
