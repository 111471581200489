import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import {
  BrowseSwCompleteBaselinesQuery,
  SortDirection, SwCompleteBaselineWhere,
  useBrowseSwCompleteBaselinesQuery,
} from '../../../../../generated/graphql';
import ErrorMessage from '../../../../common/ErrorMessage';
import { convertDataGridFilterToGraphQLWhere } from '../../../../../util/dataGridFilter';
import {
  aggregateColumn, stringColumn, weekColumn,
} from '../../../../../util/dataGridColumns';
import { SwBaselineDialogButton } from './SwBaselineDialogButton';

type Row = BrowseSwCompleteBaselinesQuery['swCompleteBaselines'][0];

const columns: GridColDef<Row>[] = [
  {
    field: 'actions',
    type: 'actions',
    width: 50,
    // eslint-disable-next-line max-len
    renderCell: (params) => <SwBaselineDialogButton swCompleteBaselineWhere={{ id: params.row.id }} />,
  },
  {
    ...stringColumn('name'),
    headerName: 'Name',
    width: 150,
  },
  {
    ...weekColumn('productionStart'),
    headerName: 'Production Start',
    width: 120,
  },
  {
    ...stringColumn('intent'),
    headerName: 'Intent',
    width: 120,
  },
  {
    ...stringColumn('consumerSwVersion'),
    headerName: 'Consumer SW Version',
    width: 180,
  },
  {
    ...stringColumn('plmItemId'),
    headerName: 'PLM Item ID',
    width: 150,
  },
  {
    ...stringColumn('state'),
    headerName: 'State',
    width: 90,
  },
  {
    ...aggregateColumn('deploymentContextsAggregate'),
    headerName: 'Development Contexts',
    width: 180,
  },
  {
    ...aggregateColumn('deploymentContexts.executionEnvironmentsAggregate'),
    headerName: 'Execution Environments',
    width: 180,
    valueGetter: (params) => {
      const deploymentContexts = params.row.deploymentContexts || [];
      return deploymentContexts.reduce(
        (sum, dc) => sum + (dc.executionEnvironmentsAggregate?.count || 0),
        0,
      );
    },
  },
  {
    ...stringColumn('baselineId'),
    headerName: 'Baseline ID',
    width: 180,
    cellClassName: 'font-monospace',
  },
];

export function SwBaselineTable() {
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 100 });
  const [where, setWhere] = useState<SwCompleteBaselineWhere>({});
  const {
    data, error, loading, previousData,
  } = useBrowseSwCompleteBaselinesQuery({
    variables: {
      where,
      options: {
        limit: paginationModel.pageSize,
        offset: paginationModel.page * paginationModel.pageSize,
        sort: [{ lastModified: SortDirection.Desc }],
      },
    },
  });

  return (
    <Box mt={2} display="flex" flexDirection="column" height="100%" component={Paper}>
      <ErrorMessage error={error}></ErrorMessage>
      <DataGrid
        density="compact"
        paginationMode="server"
        filterMode="server"
        loading={loading}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pagination
        // disableColumnMenu
        onFilterModelChange={(filter) => {
          setWhere(convertDataGridFilterToGraphQLWhere(filter, columns));
        }}
        pageSizeOptions={[]}
        columns={columns}
        rowCount={(data || previousData)?.swCompleteBaselinesAggregate.count || 0}
        rows={(data || previousData)?.swCompleteBaselines || []}
      />
    </Box>
  );
}
