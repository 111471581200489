/* eslint-disable max-len */
import { Alert } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import { SwCompleteBaselineDialogQuery } from '../../../../../../generated/graphql';
import { DataTreeRow } from '../../../../../common/data/DataTree';
import { FieldBoolean, FieldInteger, FieldString } from '../../common/FieldValues';
import { LargePartDialogButton } from '../../common/dialog-buttons/PartDialogButton';
import { UsageRuleMiniTable } from '../../bom/common/UsageRuleMiniTable';

type SwBaselineDialogSwBaseline = SwCompleteBaselineDialogQuery['swCompleteBaselines'][0];

interface Props {
  swBaseline?: SwBaselineDialogSwBaseline
}

export function SwBaselineDialogInfoPanel(props: Props) {
  const { swBaseline } = props;

  if (!swBaseline) {
    return <Alert severity="info">No baseline data provided</Alert>;
  }

  const { deploymentContexts } = swBaseline;

  if (!deploymentContexts || deploymentContexts.length === 0) {
    return <Alert severity="info">This Software Baseline has no Deployment Contexts</Alert>;
  }

  return (
    <DataTreeRow
      header={(
        <PropertyRow>
          <Property label="Name"><FieldString value={swBaseline.name} /></Property>
          <Property label="ConsumerSwVersion"><FieldString value={swBaseline.consumerSwVersion} /></Property>
          <Property label="Production Start"><FieldInteger value={swBaseline.productionStart} /></Property>
          <Property label="Intent"><FieldString value={swBaseline.intent} /></Property>
          <Property label="PlmItemId"><FieldString value={swBaseline.plmItemId} /></Property>
          <Property label="State"><FieldString value={swBaseline.state} /></Property>
          <Property label="BaselineId"><FieldString value={swBaseline.baselineId} /></Property>
        </PropertyRow>
      )}
      suffix={swBaseline.__typename}
      defaultExpanded
    >
      {deploymentContexts.map((dc, dcIndex) => (
        <DataTreeRow
          key={dcIndex}
          header={(
            <PropertyRow>
              <Property label="Name"><FieldString value={dc.name} /></Property>
              <Property label="PlmItemId"><FieldString value={dc.plmItemId} /></Property>
              <Property label="Type"><FieldString value={dc.type} /></Property>
              <Property label="Description"><FieldString value={dc.description} /></Property>
            </PropertyRow>
          )}
          suffix={dc.__typename}
          defaultExpanded
        >
          {dc.executionEnvironments.map((ee, eeIndex) => (
            <DataTreeRow
              key={eeIndex}
              header={(
                <PropertyRow>
                  <Property label="Name"><FieldString value={ee.name} /></Property>
                  <Property label="ECU"><FieldString value={ee.ecu} /></Property>
                  <Property label="ECU Address"><FieldString value={ee.ecuAddress} /></Property>
                  <Property label="PlmItemId"><FieldString value={ee.plmItemId} /></Property>
                  <Property label="Description"><FieldString value={ee.description} /></Property>
                </PropertyRow>
              )}
              suffix={ee.__typename}
              defaultExpanded
            >
              {ee.baselineHWPartUsages.map((hwpu, hwIndex) => (
                <DataTreeRow
                  key={hwIndex}
                  header={(
                    <PropertyRow>
                      <Property label="PartVersion VersionId">
                        <FieldString value={hwpu.partVersion.versionId} />
                      </Property>
                      <Property>
                        <LargePartDialogButton
                          part={hwpu.partVersion.softwarePart}
                          showName
                        />
                      </Property>
                      <Property label="UsageRules">
                        <UsageRuleMiniTable usageRules={hwpu.usageRules} />
                      </Property>
                    </PropertyRow>
                  )}
                  suffix={hwpu.__typename}
                  defaultExpanded
                >
                  {hwpu.baselineSWPartUsages.map((swpu, swIndex) => (
                    <DataTreeRow
                      key={swIndex}
                      header={(
                        <PropertyRow>
                          <Property label="PartVersion VersionId">
                            <FieldString value={swpu.partVersion.versionId} />
                          </Property>
                          <Property>
                            <LargePartDialogButton
                              part={swpu.partVersion.softwarePart}
                              showName
                            />
                          </Property>
                          <Property label="LoadedPreStation"><FieldBoolean value={swpu.loadedPreStation} /></Property>
                          <Property label="LoadedEndOfLine"><FieldBoolean value={swpu.loadedEndOfLine} /></Property>
                          <Property label="UsageRules">
                            <UsageRuleMiniTable usageRules={hwpu.usageRules} />
                          </Property>
                        </PropertyRow>
                      )}
                      suffix={hwpu.__typename}
                    />
                  ))}
                </DataTreeRow>
              ))}
            </DataTreeRow>
          ))}
        </DataTreeRow>
      ))}
    </DataTreeRow>
  );
}

function Property(props: PropsWithChildren<{ label?: string }>) {
  return (
    <Box sx={{
      mr: 2,
      display: 'flex',
      whiteSpace: 'nowrap',
      alignItems: 'center',
    }}>
      {props.label && (
        <Box sx={{ fontWeight: 'bold', mr: 1 }}>{props.label}:</Box>
      )}
      {props.children}
    </Box>
  );
}

function PropertyRow(props: PropsWithChildren) {
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      overflowX: 'auto',
    }}>
      {props.children}
    </Box>
  );
}
