import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import {
  BrowseSparePartInfoQuery, SortDirection,
  SparePartInfoWhere,
  useBrowseSparePartInfoQuery,
} from '../../../../../generated/graphql';
import ErrorMessage from '../../../../common/ErrorMessage';
import { convertDataGridFilterToGraphQLWhere } from '../../../../../util/dataGridFilter';
import {
  aggregateColumn, dateTimeColumn, stringColumn,
} from '../../../../../util/dataGridColumns';
import { SparePartInfoDialogButton } from './SparePartInfoDialogButton';

type Row = BrowseSparePartInfoQuery['sparePartInfos'][0];

const columns: GridColDef<Row>[] = [
  {
    field: 'actions',
    type: 'actions',
    width: 50,
    // eslint-disable-next-line max-len
    renderCell: (params) => <SparePartInfoDialogButton sparePartInfoWhere={{ id: params.row.id }} />,
  },
  {
    ...stringColumn('name'),
    headerName: 'Name',
    width: 100,
    cellClassName: 'font-monospace',
  },
  {
    ...stringColumn('altName'),
    headerName: 'Alt name',
    width: 100,
    cellClassName: 'font-monospace',
  },
  {
    ...stringColumn('bpsr'),
    headerName: 'BPSR',
    width: 100,
    cellClassName: 'font-monospace',
  },
  {
    ...stringColumn('dangerousGoods'),
    headerName: 'Dangerous Goods',
    width: 210,
  },
  {
    ...stringColumn('funcGroup'),
    headerName: 'Function Group',
    width: 150,
  },
  {
    ...stringColumn('infoToSupplyPlanner'),
    headerName: 'Info to Supply Planner',
    width: 180,
  },
  {
    ...stringColumn('isSoldSeparately'),
    headerName: 'Sold Separately',
    width: 150,
  },
  {
    ...stringColumn('packingComp'),
    headerName: 'Packaging Comp',
    width: 150,
  },
  {
    ...stringColumn('sparePartUnit'),
    headerName: 'Spare Part Unit',
    cellClassName: 'font-monospace',
    width: 120,
  },
  {
    ...stringColumn('exceptPart'),
    headerName: 'Except Part',
    width: 90,
  },
  {
    ...aggregateColumn('partUseInsteadAggregate'),
    headerName: 'Use Instead',
    width: 90,
  },
  {
    ...dateTimeColumn('lastModified'),
    headerName: 'Modified',
    width: 170,
  },
];

export function SparePartInfoTable() {
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 100 });
  const [where, setWhere] = useState<SparePartInfoWhere>({});
  const {
    data, error, loading, previousData,
  } = useBrowseSparePartInfoQuery({
    variables: {
      where,
      options: {
        limit: paginationModel.pageSize,
        offset: paginationModel.page * paginationModel.pageSize,
        sort: [{ lastModified: SortDirection.Desc }],
      },
    },
  });

  return (
    <Box mt={2} display="flex" flexDirection="column" height="100%" component={Paper}>
      <ErrorMessage error={error}></ErrorMessage>
      <DataGrid
        density="compact"
        paginationMode="server"
        filterMode="server"
        loading={loading}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pagination
        // disableColumnMenu
        onFilterModelChange={(filter) => {
          setWhere(convertDataGridFilterToGraphQLWhere(filter, columns));
        }}
        pageSizeOptions={[]}
        columns={columns}
        rowCount={(data || previousData)?.sparePartInfosAggregate.count || 0}
        rows={(data || previousData)?.sparePartInfos || []}
      />
    </Box>
  );
}
