import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import React from 'react';
import { FieldMiniTableVerticalDividers } from '../../common/FieldMiniTable';

interface Props {
  usageRules: {
    pmlId?: string | null;
    varCodes?: string | null;
    altVarCodes?: string | null;
  }[];
}

export function UsageRuleMiniTable(props: Props) {
  return (
    <FieldMiniTableVerticalDividers>
      {props.usageRules.map((ur) => (
        <TableRow key={ur.pmlId}>
          <TableCell>{ur.pmlId}</TableCell>
          <TableCell>{ur.varCodes || ur.altVarCodes}</TableCell>
        </TableRow>
      ))}
    </FieldMiniTableVerticalDividers>
  );
}
