import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import {
  StructureCheckSvcWhere,
  SortDirection, BrowseStructureCheckSvcQuery, useBrowseStructureCheckSvcQuery,
} from '../../../generated/graphql';
import ErrorMessage from '../../common/ErrorMessage';
import { convertDataGridFilterToGraphQLWhere } from '../../../util/dataGridFilter';
import {
  dateTimeColumn, stringColumn, subFieldColumn, weekColumn,
} from '../../../util/dataGridColumns';
import { StructureCheckSvcDialogButton } from './StructureCheckSvcDialogButton';

type Row = BrowseStructureCheckSvcQuery['structureCheckSvcs'][0];

const columns: GridColDef<Row>[] = [
  {
    field: 'actions',
    type: 'actions',
    width: 50,
    renderCell: (params) => <StructureCheckSvcDialogButton id={params.row.id} />,
  },
  {
    ...stringColumn('name'),
    headerName: 'Name',
    width: 200,
  },
  {
    ...stringColumn('sourceId'),
    headerName: 'SourceId',
    width: 220,
  },
  {
    ...weekColumn('structureWeek'),
    headerName: 'StructureWeek',
    width: 120,
  },
  {
    ...stringColumn('typeCode'),
    headerName: 'TypeCode',
    width: 90,
  },
  {
    ...subFieldColumn('dictionary'),
    headerName: 'TypeCode',
    valueGetter: (v) => v.row.configurationDictionary?.name,
    width: 90,
  },
  {
    ...dateTimeColumn('createdAt'),
    headerName: 'CreatedAt',
  },
  {
    ...dateTimeColumn('lastModified'),
    headerName: 'LastModified',
  },
];

export function StructureCheckSvcTable() {
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 100 });
  const [where, setWhere] = useState<StructureCheckSvcWhere>({});
  const {
    data, error, loading, previousData,
  } = useBrowseStructureCheckSvcQuery({
    variables: {
      where,
      options: {
        limit: paginationModel.pageSize,
        offset: paginationModel.page * paginationModel.pageSize,
        sort: [{ lastModified: SortDirection.Desc }],
      },
    },
  });

  return (
    <Box mt={2} display="flex" flexDirection="column" height="100%" component={Paper}>
      <ErrorMessage error={error}></ErrorMessage>
      <DataGrid
        density="compact"
        paginationMode="server"
        filterMode="server"
        loading={loading}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pagination
        // disableColumnMenu
        onFilterModelChange={(filter) => {
          setWhere(convertDataGridFilterToGraphQLWhere(filter, columns));
        }}
        pageSizeOptions={[]}
        columns={columns}
        rowCount={(data || previousData)?.structureCheckSvcsAggregate.count || 0}
        rows={(data || previousData)?.structureCheckSvcs || []}
      />
    </Box>
  );
}
