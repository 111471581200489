import React from 'react';
import Box from '@mui/material/Box';
import { ProjectBuildEventDialogQuery } from '../../../../../generated/graphql';

type ProjectBuildEvent = ProjectBuildEventDialogQuery['projectBuildEvents'][0];

interface Props {
  projectBuildEvent?: ProjectBuildEvent;
}

export function ProjectBuildEventsDialogInfoPanel(props: Props) {
  const { projectBuildEvent } = props;

  if (!projectBuildEvent) {
    return null;
  }

  return (
    <Box>
      {
        // todo
      }
    </Box>
  );
}
