import React, { PropsWithChildren } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import { SxProps } from '@mui/material';

interface Props {
  sx?: SxProps
}

export function TableWithBorder(props: PropsWithChildren<Props>) {
  return (
    <Paper
      variant="outlined"
      sx={props.sx}
    >
      <TableContainer>
        <Table size="small">
          {props.children}
        </Table>
      </TableContainer>
    </Paper>
  );
}
