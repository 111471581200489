import React from 'react';
import { Chip, Paper } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import Stack from '@mui/material/Stack';

interface MultipleSelectChipProps {
  onChange: (selected: string[]) => void;
  data: string[];
}

export default function MultipleSelectChip({ onChange, data }: MultipleSelectChipProps) {
  const [selected, setSelected] = React.useState<string[]>([]);

  const handleSelectOption = (option: string) => {
    const updated = selected.includes(option)
      ? selected.filter((o) => o !== option)
      : [...selected, option];

    setSelected(updated);
    onChange(updated);
  };

  return (
    <Paper sx={{ p: 2, mt: 2 }}>
      <Stack spacing={1} flexWrap="wrap" direction="row" useFlexGap>
        {data.map((option) => (
          <Chip
            key={option}
            label={option}
            clickable
            onClick={() => handleSelectOption(option)}
            // Display check icon if the option is selected
            icon={selected.includes(option) ? <CheckIcon /> : undefined}
            variant="outlined"
            color={selected.includes(option) ? 'primary' : 'default'}
          />
        ))}
      </Stack>
    </Paper>
  );
}
