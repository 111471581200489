import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Alert, Grid, Input, TextField, Tooltip, Typography,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { LoadingButton } from '@mui/lab';
import { fetchBackendFormData } from '../../../services/restClient';

interface IFormInput {
  series: string;
  project: string;
  unit: string;
  file: FileList;
  lmodFile: FileList;
}
export function PreseriesConsumptionUpload() {
  const [data, setData] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const {
    register, handleSubmit, formState: { errors }, watch,
  } = useForm<IFormInput>({});

  const onSubmit: SubmitHandler<IFormInput> = async (formInput) => {
    const formObj = new FormData();
    formObj.append('series', formInput.series);
    formObj.append('project', formInput.project);
    formObj.append('unit', formInput.unit);
    formObj.append('file', formInput.file[0]);
    if (formInput.lmodFile && formInput.lmodFile.length > 0) {
      formObj.append('lmodFile', formInput.lmodFile[0]);
    }
    setLoading(true);

    fetchBackendFormData('preseries-import', 'POST', formObj)
      .then((response) => {
        setError(null);
        setData(response);
      })
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => setLoading(false));
  };

  return (
      <>
        <form onSubmit={handleSubmit(onSubmit)}>

          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>

              {error && (
                <Alert severity="error">Error: {error}</Alert>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Series"
                error={Boolean(errors.series)}
                helperText={errors.series && errors.series.message}
                InputProps={{
                  endAdornment: (
                    <Tooltip title='Series Info'>
                      <InfoOutlinedIcon color='info' />
                    </Tooltip>),
                }}
                {...register('series', {
                  required: 'Series number',
                  maxLength: { value: 60, message: 'Maximum length is 60 characters' },
                  minLength: { value: 2, message: 'Minimum length is 2 characters' },
                })}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="project"
                error={Boolean(errors.project)}
                helperText={errors.project && errors.project.message}
                InputProps={{
                  endAdornment: (
                    <Tooltip title='project number for this test series consumption'>
                      <InfoOutlinedIcon color='info' />
                    </Tooltip>),
                }}
                {...register('project', {
                  required: 'project is required',
                  maxLength: { value: 60, message: 'Maximum length is 60 characters' },
                  minLength: { value: 2, message: 'Minimum length is 2 characters' },
                })}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="unit"
                error={Boolean(errors.project)}
                helperText={errors.project && errors.project.message}
                InputProps={{
                  endAdornment: (
                    <Tooltip title='project production start'>
                      <InfoOutlinedIcon color='info' />
                    </Tooltip>),
                }}
                {...register('unit', {
                  required: 'unit is required',
                  maxLength: { value: 60, message: 'Maximum length is 60 characters' },
                  minLength: { value: 2, message: 'Minimum length is 2 characters' },
                })}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <label htmlFor='test-series-upload'>
                <LoadingButton
                  variant="outlined"
                  component="span"
                >
                  Select Test Series File
                </LoadingButton>
                {errors.file && (
                  <Typography color="error" variant="body2">
                    {errors.file.message}
                  </Typography>
                )}
                {
                  watch('file')
                  && <Typography color="textSecondary" variant="body2">
                    {watch('file')[0]?.name}</Typography>
                }
                <Input
                  error={Boolean(errors.file)}
                  sx={{ display: 'none' }}
                  type='file'
                  id='test-series-upload'
                  {...register('file', {
                    required: 'file is required',
                  })}
                />
              </label>
            </Grid>

            <Grid item xs={12} md={6}>
              <label htmlFor='test-series-upload-lmodFile'>
                <LoadingButton
                  variant="outlined"
                  component="span"
                >
                  Select Test Series LMOD File (optional)
                </LoadingButton>
                {errors.lmodFile && (
                  <Typography color="error" variant="body2">
                    {errors.lmodFile.message}
                  </Typography>
                )}
                {
                  watch('lmodFile')
                  && <Typography color="textSecondary" variant="body2">
                    {watch('lmodFile')[0]?.name}</Typography>
                }
                <Input
                  error={Boolean(errors.lmodFile)}
                  sx={{ display: 'none' }}
                  type='file'
                  id='test-series-upload-lmodFile'
                  {...register('lmodFile')}
                />
              </label>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography component="div">
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={loading}
                >Submit Request</LoadingButton>
              </Typography>
            </Grid>
          </Grid>
        </form>

        {data && (
          <pre>{data}</pre>
        )}
      </>
  );
}
