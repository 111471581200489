import React from 'react';
import { Chip } from '@mui/material';
import Box from '@mui/material/Box';
import { dateTimeFormatWithSeconds } from '../../../../../util/date';

const colorString = '#1C6BBA';
const colorInteger = '#065F46';
const colorDatetime = '#5B21B6';
const colorNull = '#956b55';
const colorBooleanTrue = '#056134';
const colorBooleanFalse = '#bc3434';
const colorFloat = '#0F766E';
const colorEnum = '#BF834B';
const colorTypename = '#892e27';

export function FieldString(props: { value: string | null | undefined }) {
  if (props === undefined) {
    return <Box>...</Box>; // loading
  }

  if (props.value === null) {
    return <Box sx={{ color: colorNull, fontWeight: '500' }}>NULL</Box>;
  }

  if (props.value === '') {
    return <Box><Chip label="empty string" size="small" variant="outlined" sx={{ borderRadius: '4px', opacity: 0.6 }}/></Box>;
  }

  // Need some element for a wrapping tooltip to work
  return <Box sx={{ color: colorString }}>{props.value}</Box>;
}

export function FieldStringArray({ value }: { value: string[] | undefined | null }) {
  if (value === undefined) {
    return <Box>...</Box>; // loading
  }

  if (value === null) {
    return <Box sx={{ color: colorNull, fontWeight: '500' }}>NULL</Box>;
  }

  if (value.length === 0) {
    return <Box><Chip label="empty array" size="small" variant="outlined" sx={{ borderRadius: '4px' }}/></Box>;
  }

  return <Box sx={{
    '& > *': {
      display: 'inline-block',
    },
  }}>[ {value.map((v, i) => [
    <FieldString value={v} key={i}/>,
    i !== value.length - 1 ? ', ' : '',
  ])} ]</Box>;
}

export function FieldInteger(props: { value: number | null | undefined, alignRight?: boolean }) {
  if (props.value === undefined) {
    return <Box>...</Box>; // loading
  }

  if (props.value === null) {
    return <Box
      sx={{ color: colorNull, fontWeight: '500', textAlign: props.alignRight ? 'right' : undefined }}
      className="font-monospace"
    >NULL</Box>;
  }

  return <Box
    sx={{ color: colorInteger, textAlign: props.alignRight ? 'right' : undefined }}
    className="font-monospace"
  >{props.value}</Box>;
}

export function FieldFloat(props: { value: number | null | undefined, alignRight?: boolean }) {
  if (props.value === undefined) {
    return <Box>...</Box>; // loading
  }

  if (props.value === null) {
    return <Box
      sx={{ color: colorNull, fontWeight: '500', textAlign: props.alignRight ? 'right' : undefined }}
      className="font-monospace"
    >NULL</Box>;
  }

  return <Box
    sx={{ color: colorFloat, textAlign: props.alignRight ? 'right' : undefined }}
    className="font-monospace"
  >{props.value}</Box>;
}

export function FieldDatetime(props: { value: string | null | undefined }) {
  if (props.value === undefined) {
    return <Box>...</Box>; // loading
  }

  if (props.value === null) {
    return <Box sx={{ color: colorNull, fontWeight: '500' }} className="font-monospace">NULL</Box>;
  }

  return <Box sx={{ color: colorDatetime }} className="font-monospace">{dateTimeFormatWithSeconds(props.value)}</Box>;
}

export function FieldBoolean(props: { value: boolean | null | undefined }) {
  if (props.value === undefined) {
    return <Box>...</Box>; // loading
  }

  if (props.value === null) {
    return <Box sx={{ color: colorNull, fontWeight: '500' }}>NULL</Box>;
  }

  return <Box sx={{ color: props.value ? colorBooleanTrue : colorBooleanFalse, fontWeight: '500' }}>{props.value ? 'true' : 'false'}</Box>;
}

export function FieldEnum(props: { value: string | null | undefined }) {
  if (props.value === undefined) {
    return <Box>...</Box>; // loading
  }

  if (props.value === null) {
    return <Box sx={{ color: colorNull, fontWeight: '500' }}>NULL</Box>;
  }

  return <Box sx={{ color: colorEnum, fontWeight: '500' }}>{props.value}</Box>;
}

export function FieldTypename(props: { value: string | null | undefined }) {
  if (props.value === undefined) {
    return <Box>...</Box>; // loading
  }

  if (props.value === null) {
    return <Box sx={{ color: colorNull, fontWeight: '500' }}>NULL</Box>;
  }

  return <Box sx={{ color: colorTypename, fontWeight: '500' }}>{props.value}</Box>;
}
