import React from 'react';
import Typography from '@mui/material/Typography';
import { Button, LinearProgress } from '@mui/material';
import Link from '@mui/material/Link';
import { useAuth } from '../../hooks/useAuth';
import { SimpleCardLayout } from '../layout/SimpleCardLayout';
import { Section } from '../layout/Section';
import { BullMqOverview } from '../job-browser/BullMqJobOverview';
import { useBullMqImportJobNamesQuery } from '../../generated/graphql';
import MultipleSelectButton from '../common/MultipleSelectButton';
import ErrorMessage from '../common/ErrorMessage';

export function JobOverviewPage() {
  const { data: authData } = useAuth();
  const [selected, setSelected] = React.useState<string[]>([]);
  const { data, loading, error } = useBullMqImportJobNamesQuery();
  if (!authData) {
    return (
      <SimpleCardLayout>
        <Typography>You have been logged out</Typography>
        <Link href='/' mt={2} component={Button}>Back to login</Link>
      </SimpleCardLayout>
    );
  }

  return (
    <Section maxWidth={1200}>
      <ErrorMessage error={error} />
      {loading && <LinearProgress />}
      <MultipleSelectButton
        onChange={(jobsName) => setSelected(jobsName)}
        data={data?.user.bullMqJobNames || []} />
      <BullMqOverview jobNames={selected} />
    </Section>
  );
}
