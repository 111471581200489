import React from 'react';
import { ExtrasTable, ImportersTable } from './ImportersTable';
import { importers, internalImporters, otherImports } from './importers';

export function DataSourcesPage() {
  return (
    <>
      <h3>PRINS Data import procedures</h3>
      <ImportersTable importers={importers} />
      <h3>PRINS Internal procedures</h3>
      <ImportersTable importers={internalImporters} />
      <h3>Other procedures</h3>
      <ExtrasTable importers={otherImports} />
    </>
  );
}
