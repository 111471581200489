/* eslint-disable max-len */
import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Tab from '@mui/material/Tab';
import { DocumentNode } from 'graphql/language';
import ErrorMessage from '../../common/ErrorMessage';
import {
  StructureCheckSvcDialogDocument,
  StructureCheckSvcDialogQuery,
  useStructureCheckSvcDialogQuery,
} from '../../../generated/graphql';
import { PrettyJson } from '../../common/data/PrettyJson';
import { PrettyQuery } from '../../common/data/PrettyQuery';
import { DialogLoader } from '../../common/dialog/DialogLoader';
import { DialogHeader } from '../../common/dialog/DialogHeader';
import { SlideUpTransition } from '../../common/dialog/SlideUpTransition';
import { CpvSetCpvTable } from '../../documentation/pages/browser/cpv-sets/Dialog/CpvSetCpvTable';

interface Props {
  id: string,
  close: () => void
}
export function StructureCheckSvcDialog(props: Props) {
  const [open, setOpen] = useState(true);
  const [tab, setTab] = React.useState('preview');

  const { data, loading, error } = useStructureCheckSvcDialogQuery({
    variables: {
      where: {
        id: props.id,
      },
    },
  });

  const entity = data?.structureCheckSvcs[0];

  const handleClose = () => {
    setOpen(false);
    setTimeout(props.close, 1000);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth={true}
      maxWidth="xl"
      TransitionComponent={SlideUpTransition}
      sx={{
        transform: 'translateZ(0)',
      }}
    >
      <DialogHeader
        type="StructureCheckSvc"
        primaryText={entity?.name}
        onCloseClick={handleClose}
      />
      {loading && <DialogLoader/>}
      <ErrorMessage error={error} />

      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={(e, v) => setTab(v)}>
            <Tab label="Preview" value="preview" />
            <Tab label="Json" value="json" />
            <Tab label="Example Query" value="query" />
          </TabList>
        </Box>
        <TabPanel value="preview"><DataTab data={data}></DataTab></TabPanel>
        <TabPanel value="json"><JsonTab data={data}></JsonTab></TabPanel>
        <TabPanel value="query"><QueryTab query={StructureCheckSvcDialogDocument}></QueryTab></TabPanel>
      </TabContext>
    </Dialog>
  );
}

function DataTab(props: { data?: StructureCheckSvcDialogQuery }) {
  const entity = props.data?.structureCheckSvcs[0];

  return (
    <>
      <Box p={2}>
        <CpvSetCpvTable
          title={'Values (Configuration Parameter Values)'}
          values={entity?.values || []}
        />
      </Box>
    </>
  );
}

function JsonTab(props: { data?: StructureCheckSvcDialogQuery }) {
  if (!props.data) {
    return null;
  }

  return (
    <PrettyJson data={props.data} />
  );
}

function QueryTab(props: { query: DocumentNode }) {
  return (
    <>
      <PrettyQuery query={props.query.loc?.source.body.trim() || ''} />
    </>
  );
}
