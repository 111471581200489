import React from 'react';
import {
  TestSeriesConsumptionsTable,
} from '../../documentation/pages/browser/test-series-consumptions/TestSeriesConsumptionsTable';
import { TabsPage } from '../../layout/TabsPage';
import {
  ProjectBuildEventsTable,
} from '../../documentation/pages/browser/project-build-events/ProjectBuildEventsTable';

export function TestSeriesBrowserPage() {
  return (
    <>
      <TabsPage tabs={[
        {
          label: 'Project Build Events',
          content: <ProjectBuildEventsTable/>,
        },
        {
          label: 'Test Series Consumptions',
          content: <TestSeriesConsumptionsTable/>,
        },
      ]} />
    </>
  );
}
