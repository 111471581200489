import React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { useAuth } from '../../hooks/useAuth';
import { SimpleCardLayout } from '../layout/SimpleCardLayout';
import { DataIssuesDashboard } from '../control-panel/DataQuality/DataIssuesDashboard';
import { UploadDataValidation } from '../control-panel/DataQuality/DataQualityDashboard';
import { UsageRulesDashboard } from '../control-panel/Upstream/UsageRulesDashboard';

export function DataQualityPage() {
  const { data: authData } = useAuth();

  if (!authData) {
    return (
      <SimpleCardLayout>
        <Typography>You have been logged out</Typography>
        <Link href='/' mt={2} component={Button}>Back to login</Link>
      </SimpleCardLayout>
    );
  }

  return (
    <>
      <DataIssuesDashboard />
      <UploadDataValidation />
      <UsageRulesDashboard />
    </>
  );
}
