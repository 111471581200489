import { Button } from '@mui/material';
import React, { useState } from 'react';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import Box from '@mui/material/Box';
import { ConfigParamValuesFragment, useFeatureBreakdownOptionsQuery } from '../../../generated/graphql';
import ErrorMessage from '../../common/ErrorMessage';
import { SelectValue } from '../../documentation/pages/browser/cpv-sets/StoredCpvSetsFilter';

interface Props {
  typeCode: string;
  value: string,
  onChange: (v: string[]) => void,
}

type SelectedValues = {
  [key: string]: ConfigParamValuesFragment;
};

export function FeatureBuilderLegacyFormat(props: Props) {
  const [selectedConfigParamValues, setSelectedConfigParamValues] = useState<SelectedValues>({});

  const { data, loading, error } = useFeatureBreakdownOptionsQuery({
    variables: {
      typeCode: props.typeCode,
    },
    onCompleted: (result) => {
      // set selectedConfigParamValues to default values
      // Take a props.value that looks like this
      //  "AA05\nAP01\nCB01\nCFFD",
      // and turn it into an object that looks like this
      // {
      //   'AA': { code: '05', name: 'Name 1' },
      // }
      const selected: SelectedValues = {};
      props.value.split('\n').forEach((line) => {
        const family = line.trim().substring(0, 2);
        const feature = line.trim().substring(2, 4);
        const cpv = result.configurationDictionaries[0].configurationParameters
          .find((cp) => cp.altCode === family)?.configurationParameterValues
          .find((cpv2) => cpv2.altCode === feature);
        if (cpv) {
          selected[family] = cpv;
        }
      });
      setSelectedConfigParamValues(selected);
    },
    skip: props.typeCode.length !== 4,
  });

  const update = (values: SelectedValues) => {
    setSelectedConfigParamValues(values);
    const lines = Object.entries(values).map(([key, value]) => `${key}${value.altCode}`);
    props.onChange(lines);
  };

  const randomize = () => {
    if (data) {
      const selected: SelectedValues = {};
      data.configurationDictionaries[0].configurationParameters.forEach((cp) => {
        const randomIndex = Math.floor(Math.random() * cp.configurationParameterValues.length);
        if (cp.altCode) { selected[cp.altCode] = cp.configurationParameterValues[randomIndex]; }
      });
      update(selected);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  const configurationParameters = data?.configurationDictionaries[0]?.configurationParameters;

  return (
    <div>
      {props.typeCode.length !== 4 && (
        <div>Typecode must be 4 characters</div>
      )}
      {data?.configurationDictionaries.length === 0 && (
        <div>No configuration dictionary found</div>
      )}
      {data?.configurationDictionaries.length && (
        <Box mb={1} sx={{ textAlign: 'right' }}>
          <Button startIcon={<ShuffleIcon />} onClick={() => randomize()} size="small">Randomize</Button>
        </Box>
      )}
      {configurationParameters?.map(({ altCode, name, configurationParameterValues }) => (
        altCode && (
          <Box key={name} mb={1}>
            <SelectValue
              options={configurationParameterValues}
              label={`${altCode} - ${name}`}
              selectedValue={selectedConfigParamValues[altCode]}
              onChange={(option) => {
                if (option) {
                  update({
                    ...selectedConfigParamValues,
                    [altCode]: option,
                  });
                } else {
                  delete selectedConfigParamValues[altCode];
                  update({ ...selectedConfigParamValues });
                }
              }}
            ></SelectValue>
          </Box>
        )
      ))}
    </div>
  );
}
