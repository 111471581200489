import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React from 'react';
import { CleansingJobResultTableRowFragment } from '../../generated/graphql';
import {
  dateTimeColumn, numberColumn, stringArrayColumn, stringColumn,

} from '../../util/dataGridColumns';

const columns: GridColDef<CleansingJobResultTableRowFragment>[] = [
  {
    ...stringColumn('type'),
    headerName: 'Type',
    sortable: true,
    width: 200,
  },
  {
    ...numberColumn('deletedRecords'),
    headerName: 'Deleted Records',
    sortable: true,
    width: 140,
    align: 'left',
  },
  {
    ...stringArrayColumn('sourceIds'),
    headerName: 'Source IDs',
    sortable: false,
    width: 200,
  },
  {
    ...dateTimeColumn('createdAt'),
    headerName: 'Job ran',
    sortable: true,
    width: 200,
  },
];

interface CleansingJobResultTableProps {
  jobResultList: CleansingJobResultTableRowFragment[];
}

export function CleansingJobResultTable({ jobResultList }: CleansingJobResultTableProps) {
  return (
    <DataGrid
      loading={false}
      pagination
      getRowHeight={() => undefined}
      columns={columns}
      rowCount={jobResultList.length}
      rows={jobResultList}
      autoHeight={true}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: 'createdAt',
              sort: 'desc',
            },
          ],
        },
      }}
      getRowId={(row: CleansingJobResultTableRowFragment) => row.id}
    />
  );
}
