import React from 'react';
import Box from '@mui/material/Box';
import { QuantityCheckSvc } from '../../tools/quantity-check-svc/QuantityCheckSvc';
import { StructureCheckSvcTable } from '../../tools/quantity-check-svc/StructureCheckSVCTable';

export function QuantityCheckSvcPage() {
  return (
    <Box mt={2}>
      <Box mb={2}>
        <StructureCheckSvcTable/>
      </Box>
      <QuantityCheckSvc/>
    </Box>
  );
}
