import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridFilterInputValueProps, GridColDef } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import { TextField } from '@mui/material';
import {
  BrowseConsumptionRecordsQuery, ConsumptionRecordWhere, SortDirection,
  useBrowseConsumptionRecordsQuery,
} from '../../../../../generated/graphql';
import ErrorMessage from '../../../../common/ErrorMessage';
import { convertDataGridFilterToGraphQLWhere } from '../../../../../util/dataGridFilter';
import {
  booleanColumn, dateTimeColumn, numberColumn, stringColumn, subFieldColumn, weekColumn,
} from '../../../../../util/dataGridColumns';
import { ConsumptionRecordDialogButton } from './ConsumptionRecordDialogButton';

type Row = BrowseConsumptionRecordsQuery['consumptionRecords'][0];

type CustomFilterInputComponentProps = GridFilterInputValueProps;

const CustomFilterInputComponent: React.FC<CustomFilterInputComponentProps> = ({
  item, applyValue,
}) => (
  <TextField
    variant="standard"
    label="Value"
    value={item.value || ''}
    onChange={(e) => applyValue({ ...item, value: e.target.value })}
    placeholder="Filter value"
    fullWidth
    InputLabelProps={{
      shrink: true,
    }}
  />
);

const columns: GridColDef<Row>[] = [
  {
    field: 'actions',
    type: 'actions',
    width: 50,
    renderCell: (params) => <ConsumptionRecordDialogButton id={params.row.id} />,
  },
  {
    ...subFieldColumn('plant.plantCode'),
    headerName: 'Plant',
    valueGetter: (v) => v.row.plant?.plantCode,
    width: 40,
    filterable: true,
    filterOperators: [
      {
        label: 'contains',
        value: 'contains',
        InputComponent: CustomFilterInputComponent,
        getApplyFilterFn: () => () => true,
      },
    ],
  },
  {
    ...subFieldColumn('mbom.ebom.productContext.typeCode'),
    headerName: 'TypeCode',
    valueGetter: (v) => v.row.mbom?.ebom.productContext?.typeCode,
    width: 100,
  },
  {
    ...subFieldColumn('mbom.ebom.productContext.typeDesignation'),
    headerName: 'TypeDesignation',
    valueGetter: (v) => v.row.mbom?.ebom.productContext?.typeDesignation,
    width: 100,
  },
  {
    ...weekColumn('effectiveInWeek'),
    headerName: 'In Week',
    width: 100,
  },
  {
    ...weekColumn('effectiveOutWeek'),
    headerName: 'Out Week',
    width: 100,
  },
  {
    ...subFieldColumn('partUsage.part.partNumber'),
    headerName: 'Part n#',
    valueGetter: (v) => (v.row.partUsage?.part || v.row.partUsage?.softwarePart)?.partNumber,
    width: 120,
  },
  {
    ...subFieldColumn('partUsage.part.partType'),
    headerName: 'Part Type',
    valueGetter: (v) => (v.row.partUsage?.part || v.row.partUsage?.softwarePart)?.partType,
    width: 90,
  },
  {
    ...subFieldColumn('partUsage.part.name'),
    headerName: 'Part Name',
    valueGetter: (v) => (v.row.partUsage?.part || v.row.partUsage?.softwarePart)?.name,
    width: 180,
  },
  {
    ...subFieldColumn('changeOrderIssueAdd.coiNumber'),
    headerName: 'Add',
    valueGetter: (v) => v.row.changeOrderIssueAdd?.coiNumber,
    width: 100,
  },
  {
    ...subFieldColumn('changeOrderIssueDel.coiNumber'),
    headerName: 'Del',
    valueGetter: (v) => v.row.changeOrderIssueDel?.coiNumber,
    width: 100,
  },
  {
    ...numberColumn('POST'),
    width: 100,
  },
  {
    ...numberColumn('quantity'),
    width: 100,
  },
  {
    ...stringColumn('deliveryUnitType'),
    headerName: 'Type',
    width: 100,
  },
  {
    ...stringColumn('duMarking'),
    headerName: 'DU-marking',
    width: 100,
  },
  {
    ...booleanColumn('isPartOfDeliveryModule'),
    headerName: 'isPartOfDeliveryModule',
    width: 100,
  },
  {
    ...dateTimeColumn('createdAt'),
    headerName: 'Created At',
  },
  {
    ...dateTimeColumn('lastModified'),
    headerName: 'Modified At',
  },
];

export function ConsumptionRecordsTable() {
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 100 });
  const [where, setWhere] = useState<ConsumptionRecordWhere>({});
  const {
    data, error, loading, previousData,
  } = useBrowseConsumptionRecordsQuery({
    variables: {
      where,
      options: {
        limit: paginationModel.pageSize,
        offset: paginationModel.page * paginationModel.pageSize,
        sort: [{ lastModified: SortDirection.Desc }],
      },
    },
  });

  return (
    <Box mt={2} display="flex" flexDirection="column" height="100%" component={Paper}>
      <ErrorMessage error={error}></ErrorMessage>
      <DataGrid
        density="compact"
        paginationMode="server"
        filterMode="server"
        loading={loading}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pagination
        // disableColumnMenu
        onFilterModelChange={(filter) => {
          const newWhere = convertDataGridFilterToGraphQLWhere(filter, columns);
          // Check if there's a filter applied to the plant code
          const plantCodeFilterKey = Object.keys(newWhere).find((key) => key.includes('plant.plantCode_CONTAINS'));
          if (plantCodeFilterKey) {
            const plantCodeValue = newWhere[plantCodeFilterKey];
            newWhere.sourceId_STARTS_WITH = plantCodeValue;
            delete newWhere[plantCodeFilterKey];
          }

          setWhere(newWhere);
        }}
        pageSizeOptions={[]}
        columns={columns}
        getRowClassName={(params) => (params.row.isRevoked ? 'revoked' : '')}
        rowCount={(data || previousData)?.consumptionRecordsAggregate.count || 0}
        rows={(data || previousData)?.consumptionRecords || []}
      />
    </Box>
  );
}
