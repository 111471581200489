import React from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { IconButton } from '@mui/material';
import { createDialog } from '../../../../../hooks/useDialogs';
import { ProjectBuildEventDialog } from './ProjectBuildEventDialog';

interface Props {
  id: string,
  size?: 'small' | 'medium' | 'large',
}

export function ProjectBuildEventDialogButton(props: Props) {
  return (
    <IconButton
      size={props.size || 'medium'}
      color="primary"
      onClick={(e) => {
        e.stopPropagation();
        createDialog((close) => <ProjectBuildEventDialog close={close} id={props.id}/>);
      }}>
      <OpenInNewIcon/>
    </IconButton>
  );
}
