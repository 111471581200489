import React, { PropsWithChildren } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import { SxProps } from '@mui/material';

interface Props {
  sx?: SxProps
}

export function TableWithVerticalDividers(props: PropsWithChildren<Props>) {
  return (
    <Paper sx={{
      '& .MuiTableCell-root:not(:last-child)': {
        borderRight: '1px solid #000',
        borderColor: 'divider',
      },
      ...props.sx,
    }}
    variant="outlined"
    >
      <TableContainer>
        <Table size="small">
          {props.children}
        </Table>
      </TableContainer>
    </Paper>
  );
}
