import React, { useState } from 'react';
import { Box, Paper } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
  BrowseProjectBuildEventsQuery, SortDirection,
  ProductFamilyWhere,
  useBrowseProjectBuildEventsQuery,
} from '../../../../../generated/graphql';
import ErrorMessage from '../../../../common/ErrorMessage';
import {
  aggregateColumn,
  booleanColumn, dateTimeColumn, stringColumn, subFieldColumn, weekColumn,
} from '../../../../../util/dataGridColumns';
import { ProjectBuildEventDialogButton } from './ProjectBuildEventDialogButton';
import { convertDataGridFilterToGraphQLWhere } from '../../../../../util/dataGridFilter';

type Row = BrowseProjectBuildEventsQuery['projectBuildEvents'][0];

const columns: GridColDef<Row>[] = [
  {
    field: 'actions',
    type: 'actions',
    width: 50,
    renderCell: (params) => <ProjectBuildEventDialogButton id={params.row.id}/>,
  },
  {
    ...stringColumn('event'),
  },
  {
    ...stringColumn('project'),
  },
  {
    ...weekColumn('adminStartWeek'),
  },
  {
    ...weekColumn('adminEndWeek'),
  },
  {
    ...weekColumn('buildStartWeek'),
  },
  {
    ...weekColumn('buildEndWeek'),
  },
  {
    ...weekColumn('productionStart'),
  },
  {
    ...booleanColumn('controlCheck'),
  },
  {
    ...aggregateColumn('testSeriesConsumptionsAggregate'),
    headerName: 'Consumptions',
  },
  {
    ...subFieldColumn('productContexts.typeCode'),
    width: 120,
    headerName: 'typeCodes',
    cellClassName: 'font-monospace',
    valueGetter: (v) => {
      return v.row.productContexts.map((pc) => pc.typeCode).join(', ');
    },
  },
  // adminEndWeek
  // adminStartWeek
  // buildEndWeek
  // buildStartWeek
  // controlCheck
  // createdAt
  // lastModified
  // deletedAt
  // event
  // orderToFactory
  // productionStart
  // project
  {
    ...dateTimeColumn('deletedAt'),
  },
  {
    ...dateTimeColumn('createdAt'),
  },
  {
    ...dateTimeColumn('lastModified'),
  },
];

export function ProjectBuildEventsTable() {
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 100 });
  const [where, setWhere] = useState<ProductFamilyWhere>({});
  const {
    data, error, loading, previousData,
  } = useBrowseProjectBuildEventsQuery({
    variables: {
      where,
      options: {
        limit: paginationModel.pageSize,
        offset: paginationModel.page * paginationModel.pageSize,
        sort: [{ lastModified: SortDirection.Desc }],
      },
    },
  });

  return (
    <Box display="flex" flexDirection="column" height="100%" component={Paper}>
      <ErrorMessage error={error}/>
      <DataGrid
       density="compact"
       paginationMode="server"
       loading={loading}
       paginationModel={paginationModel}
       onPaginationModelChange={setPaginationModel}
       pagination
       onFilterModelChange={(filter) => {
         setWhere(convertDataGridFilterToGraphQLWhere(filter, columns));
       }}
       columns={columns}
       rowCount={(data || previousData)?.projectBuildEventsAggregate.count || 0}
       rows={(data || previousData)?.projectBuildEvents || []}
       />
    </Box>
  );
}
