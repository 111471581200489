import React from 'react';
import Box from '@mui/material/Box';
import { yearWeekFormat } from '../../../util/date';
import { PartLabelBreakdown } from '../../tools/part-label-breakdown/PartLabelBreakdown';

export function PartLabelBreakdownPage() {
  return (
    <Box mt={2}>
      <PartLabelBreakdown
        defaultValues={{
          structureWeek: yearWeekFormat(),
          typeCode: '',
          features: [],
          plantCode: 21,
        }}
      />
    </Box>
  );
}
