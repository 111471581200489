/* eslint-disable no-underscore-dangle */

import 'graphql-voyager/dist/voyager.css';

import React, { useEffect, useState } from 'react';
import { Voyager, voyagerIntrospectionQuery } from 'graphql-voyager';
import Alert from '@mui/material/Alert';
import { IntrospectionSchema } from 'graphql/utilities/getIntrospectionQuery';
import { useSelectedEnvironment } from '../../../hooks/useSelectedEnvironment';
import { filterIntrospection } from '../../../util/filterIntrospection';
import { currentAuth } from '../../../hooks/useAuth';
import { LoadingCircularCentered } from '../LoadingCircularCentered';

interface IntrospectionResult {
  data: {
    __schema: IntrospectionSchema;
  };
}

export function GraphExplorer() {
  const [selectedEnv] = useSelectedEnvironment();
  const [error, setError] = useState<Error | unknown | null>();
  const [introspectionResult, setIntrospectionResult] = useState<IntrospectionResult | null>();
  const auth = currentAuth();

  const introspectionProvider = async () => {
    try {
      const result = await fetch(selectedEnv.graphQLEndpoint, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          authorization: auth ? `Bearer ${auth.idToken}` : '',
        },
        body: JSON.stringify({ query: voyagerIntrospectionQuery }),
      }).then((response) => response.json());

      if (result.errors && result.errors[0].message) {
        setError(result.errors[0].message);
        return;
      }

      setError(null);

      setIntrospectionResult({
        data: {
          __schema: filterIntrospection(result.data.__schema),
        },
      });
    } catch (e: unknown) {
      setError(e);
    }
  };

  useEffect(() => {
    introspectionProvider().then();
  }, []);

  if (!introspectionResult) {
    return <LoadingCircularCentered py={10} visible text="Loading GraphQL Schema"/>;
  }

  return (
    <>
      {error && <Alert severity="error">{`${error}`}</Alert>}
      <Voyager
        displayOptions={{
          // hideRoot: true,
          sortByAlphabet: true,
        }}
        introspection={Promise.resolve(introspectionResult)}
      />
    </>
  );
}
