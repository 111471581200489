import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import {
  BrowseUsageRulesQuery, SortDirection, UsageRuleWhere, useBrowseUsageRulesQuery,
} from '../../../../../generated/graphql';
import ErrorMessage from '../../../../common/ErrorMessage';
import { UsageRuleDialogButton } from './UsageRuleDialogButton';
import { convertDataGridFilterToGraphQLWhere } from '../../../../../util/dataGridFilter';
import {
  aggregateColumn, dateTimeColumn, enumColumn, stringColumn,
} from '../../../../../util/dataGridColumns';

type Row = BrowseUsageRulesQuery['usageRules'][0];

const columns: GridColDef<Row>[] = [
  {
    field: 'actions',
    type: 'actions',
    width: 50,
    renderCell: (params) => <UsageRuleDialogButton where={{ id: params.row.id }}/>,
  },
  {
    ...enumColumn('subType'),
    headerName: 'Sub Type',
    valueOptions: [
      'SINGLE_PML',
      'COMBINATION_PML',
      'VARIANT_COMBINATION',
    ],
    width: 200,
  },
  {
    ...stringColumn('typeCode'),
    width: 80,
  },
  {
    ...stringColumn('pmlId'),
    headerName: 'PML #',
    width: 120,
    cellClassName: 'font-monospace',
  },
  {
    ...aggregateColumn('plants'),
    headerName: 'Plant Codes',
    width: 100,
    renderCell: (params) => <>{ params.row.plants.length ? params.row.plants.map((x) => x.plantCode).join(',') : '-'}</>,
  },
  {
    ...aggregateColumn('partUsagesAggregate'),
    headerName: 'Part Usages',
    width: 100,
  },
  {
    ...aggregateColumn('cpvsAggregate'),
    headerName: 'CPVs',
  },
  {
    ...stringColumn('varCodes'),
    headerName: 'varCodes',
    width: 350,
    cellClassName: 'font-monospace',
  },
  {
    ...stringColumn('altVarCodes'),
    headerName: 'altVarCodes',
    width: 250,
    cellClassName: 'font-monospace',
  },
  {
    ...dateTimeColumn('lastModified'),
    headerName: 'Modified',
    width: 170,
  },
];

export function UsageRuleTable() {
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 100 });
  const [where, setWhere] = useState<UsageRuleWhere>({});
  const {
    data, error, loading, previousData,
  } = useBrowseUsageRulesQuery({
    variables: {
      where,
      options: {
        limit: paginationModel.pageSize,
        offset: paginationModel.page * paginationModel.pageSize,
        sort: [{ lastModified: SortDirection.Desc }],
      },
    },
  });

  return (
    <Box mt={2} display="flex" flexDirection="column" height="100%" component={Paper}>
      <ErrorMessage error={error}></ErrorMessage>
      <DataGrid
        density="compact"
        paginationMode="server"
        filterMode="server"
        loading={loading}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pagination
        // disableColumnMenu
        onFilterModelChange={(filter) => {
          setWhere(convertDataGridFilterToGraphQLWhere(filter, columns));
        }}
        pageSizeOptions={[]}
        columns={columns}
        rowCount={(data || previousData)?.usageRulesAggregate.count || 0}
        rows={(data || previousData)?.usageRules || []}
      />
    </Box>
  );
}
