import React from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
  ProjectBuildEventDialog_TestSeriesConsumptionFragment,
} from '../../../../../generated/graphql';
import { FieldInteger, FieldString } from '../common/FieldValues';
import { LargePartDialogButton } from '../common/dialog-buttons/PartDialogButton';
import { UsageRuleMiniTable } from '../bom/common/UsageRuleMiniTable';
import { FieldCoi } from '../common/FieldCoi';

interface PbeTestSeriesConsumptionsTableProps {
  rows: ProjectBuildEventDialog_TestSeriesConsumptionFragment[]
}

export function PbeTestSeriesConsumptionsTable(props: PbeTestSeriesConsumptionsTableProps) {
  const { rows } = props;

  return (
    <Paper sx={{ mb: 1, mr: 1 }} variant="outlined">
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox"></TableCell>
              <TableCell align="right">POST</TableCell>
              <TableCell align="right">quantity</TableCell>
              <TableCell>part</TableCell>
              <TableCell>partVersion</TableCell>
              <TableCell>UsageRules</TableCell>
              <TableCell>Add</TableCell>
              <TableCell>Del</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell padding="checkbox">
                </TableCell>
                <TableCell align="right">
                  <FieldInteger value={row.POST} alignRight />
                </TableCell>
                <TableCell>
                  <FieldInteger value={row.quantity} alignRight />
                </TableCell>
                <TableCell>
                  <LargePartDialogButton
                    part={
                      row.partVersion?.part
                      || row.partVersion?.softwarePart
                      || row.partVersion?.deliveryModule
                      || row.part
                      || row.softwarePart
                      || row.deliveryModule
                  }
                  />
                </TableCell>
                <TableCell>
                  <FieldString value={row.partVersion?.versionId} />
                </TableCell>
                <TableCell>
                  <UsageRuleMiniTable usageRules={row.usageRules} />
                </TableCell>
                <TableCell>
                   <FieldCoi
                    coiNumber={row.changeOrderIssueAdd?.coiNumber}
                    coiNumberTooltip={'.changeOrderIssueAdd.coiNumber'}
                    week={row.effectiveInWeek}
                    weekTooltip={'.effectiveInWeek'}
                   />
                </TableCell>
                <TableCell>
                   <FieldCoi
                    coiNumber={row.changeOrderIssueDel?.coiNumber}
                    coiNumberTooltip={'.changeOrderIssueDel.coiNumber'}
                    week={row.effectiveOutWeek}
                    weekTooltip={'.effectiveOutWeek'}
                   />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
