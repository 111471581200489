import React, {
  useEffect, useState,
} from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  CheckCircle, CheckOutlined, DoNotDisturb, Error, ExpandMore,
} from '@mui/icons-material';
import { Subject } from 'rxjs';
import Box from '@mui/material/Box';
import { GraphQLCode } from '../../common/GraphqlCodeBlock/GraphQLCode';
import { RunQueryButton } from '../../common/GraphqlCodeBlock/GraphView/RunQueryButton';
import { ApplicationOperationEditButton } from '../../control-panel/Operations/ApplicationOperationEditButton';
import {
  ApplicationDetailsForOperationFragment,
  OperationDetailsFragment,
} from '../../../generated/graphql';
import { safeParseJson } from './operations.util';
import { OperationDetails } from './OperationDetails';
import {
  ApplicationOperationEditNotesButton,
} from '../../control-panel/Operations/ApplicationOperationEditNotesButton';
import { LastModifiedRelativeTime } from './LastModifiedRelativeTime';

interface Props {
  operation: OperationDetailsFragment;
  application: ApplicationDetailsForOperationFragment;
  refetch: () => void;
  callAll?: Subject<null>;
  incrementSuccessful: () => void;
  incrementErrors: () => void;
  totalExecutionTime: (latestExecTime: number) => void;
  stressRuns?: number;
}

export function Operation(props: Props) {
  const {
    operation, application, refetch, callAll,
    incrementSuccessful, incrementErrors,
    totalExecutionTime, stressRuns,
  } = props;

  const [expanded, setExpanded] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<JSX.Element>();
  const [executionTime, setExecutionTime] = useState<number>(0);
  const [overrideDraft, setOverrideDraft] = useState<boolean | null>(null);
  const parsedCurrentVariables = safeParseJson(props.operation?.variables, {});

  const callQuery = new Subject<null>();
  const shouldSkip = overrideDraft ?? !!operation.draft;

  useEffect(() => {
    if (!callAll || shouldSkip) {
      return () => {
      };
    }
    const subscription = callAll.subscribe(() => callQuery.next(null));
    return () => subscription.unsubscribe();
  }, [callAll, shouldSkip]);

  const increment = (e: { success: boolean; executionTime: number }) => {
    totalExecutionTime(e.executionTime);
    if (e.success) {
      incrementSuccessful();
    } else {
      incrementErrors();
    }
  };

  return (
    <Accordion onChange={(e, exp) => setExpanded(exp)}>
      <AccordionSummary expandIcon={<ExpandMore />} sx={{ '& .MuiAccordionSummary-content ': { margin: 0 } }}>
        <Grid container spacing={1} alignItems='center' mr={2} mt={-1}>
          <Grid item>
            <ToggleSkip
              update={(value) => setOverrideDraft(value)}
              overrideSkip={overrideDraft}
              defaultSkip={!!props.operation.draft}
            />
          </Grid>
          <Grid item>
            <RunQueryButton
              onClick={() => callQuery.next(null)}
              loading={loading}
            />
          </Grid>
          <Grid item sx={{ flexGrow: 1 }}>
            {operation.name}
          </Grid>
          <Grid item>
            {executionTime > 0 && `${Math.round(executionTime).toLocaleString('sv-SE')} ms`}
          </Grid>
          <Grid item>
            {success}
          </Grid>
          <Grid item>
            <LastModifiedRelativeTime lastModified={operation.lastModified} />
          </Grid>
          <Grid item onClick={(e) => e.stopPropagation()}>
            <ApplicationOperationEditNotesButton
              application={application}
              operation={operation}
              onComplete={refetch}
            />
          </Grid>
          <Grid item onClick={(e) => e.stopPropagation()}>
            <ApplicationOperationEditButton
              application={application}
              operation={operation}
              onComplete={refetch}
            />
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <GraphQLCode
          code={operation.query}
          variables={parsedCurrentVariables}
          showGraph={true}
          showDagGraph={true}
          callSubject={callQuery}
          stressRuns={stressRuns}
          skipRender={!expanded}
          onCompleted={(e) => {
            setExecutionTime(e.executionTime);
            setLoading(false);
            setSuccess(e.success ? <CheckCircle color='success' />
              : <Error color='error' />);
            increment(e);
          }}
          onChange={(state) => {
            setLoading(state.loading);
          }}
        />
        <OperationDetails operation={operation} />
      </AccordionDetails>
    </Accordion>
  );
}

interface ToggleSkipProps {
  update: (value: boolean) => void,
  overrideSkip: boolean | null,
  defaultSkip: boolean,
}

function ToggleSkip(props: ToggleSkipProps) {
  const { update, overrideSkip, defaultSkip } = props;

  const color = overrideSkip === null ? 'disabled' : 'inherit';
  const skip = overrideSkip ?? defaultSkip;

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      {skip && (
        <Tooltip title={'Marked as draft, click to unskip'}>
          <IconButton onClick={() => update(!skip)}>
            <DoNotDisturb color={color} />
          </IconButton>
        </Tooltip>
      )}
      {!skip && (
        <Tooltip title={'Click to mark as draft and skip when running all operations'}>
          <IconButton onClick={() => update(!skip)}>
            <CheckOutlined color={color} />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
}
