import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import Typography from '@mui/material/Typography';
import { Monospaced } from '../../common/text/Monospaced';
import { PartLabelBreakDownQuery } from '../../../generated/graphql';
import { PartDialogButton } from '../../documentation/pages/browser/parts/PartDialogButton';

type PartLabelData = PartLabelBreakDownQuery['partLabelBreakDown']['parts'];

export function PartLabelBreakdownResultTable(props: { partLabels: PartLabelData }) {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableCell>PartNumber</TableCell>
          <TableCell>LabelDefinition</TableCell>
          <TableCell>LabelPosition</TableCell>
          <TableCell>LabelTextParameter</TableCell>
        </TableHead>
        {props.partLabels.map((label, i) => (
          <TableRow key={i}>
             <TableCell>
              <Monospaced>{label.partNumber}</Monospaced>
              <PartDialogButton where={{ partNumber: label.partNumber }} size="small"/>
            </TableCell>
            <TableCell >{label.labelDefinition}</TableCell>
            <TableCell >{label.labelPosition}</TableCell>
            <TableCell >{label.labelTextParameter}</TableCell>
          </TableRow>
        ))}
      </Table>
      {props.partLabels.length === 0 && (
        <Typography variant="body2" color="text.secondary" sx={{ p: 2 }}>
          No results. Try changing the input.
          Maybe the structure week is wrong or change the plant code?
        </Typography>
      )}
    </TableContainer>
  );
}
