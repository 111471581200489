import React, { useState } from 'react';
import { Box, Paper } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
  BrowseProductFamiliesQuery, SortDirection,
  ProductFamilyWhere,
  useBrowseProductFamiliesQuery,
} from '../../../../../generated/graphql';
import ErrorMessage from '../../../../common/ErrorMessage';
import { dateTimeColumn, stringColumn } from '../../../../../util/dataGridColumns';
import { ProductFamiliesDialogButton } from './ProductFamiliesDialogButton';
import { convertDataGridFilterToGraphQLWhere } from '../../../../../util/dataGridFilter';

type Row = BrowseProductFamiliesQuery['productFamilies'][0];

const columns: GridColDef<Row>[] = [
  {
    field: 'actions',
    type: 'actions',
    width: 50,
    renderCell: (params) => <ProductFamiliesDialogButton id={params.row.id}/>,
  },
  {
    ...stringColumn('pType'),
    headerName: 'PType',
    width: 100,
    cellClassName: 'font-monospace',
  },
  {
    ...stringColumn('name'),
    headerName: 'Product Family name',
    width: 500,
  },
  {
    ...dateTimeColumn('lastModified'),
    headerName: 'Modified',
  },
];

export function ProductFamiliesTable() {
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 100 });
  const [where, setWhere] = useState<ProductFamilyWhere>({});
  const {
    data, error, loading, previousData,
  } = useBrowseProductFamiliesQuery({
    variables: {
      where,
      options: {
        limit: paginationModel.pageSize,
        offset: paginationModel.page * paginationModel.pageSize,
        sort: [{ lastModified: SortDirection.Desc }],
      },
    },
  });

  return (
    <Box mt={2} display="flex" flexDirection="column" height="100%" component={Paper}>
      <ErrorMessage error={error}/>
      <DataGrid
       density="compact"
       paginationMode="server"
       loading={loading}
       paginationModel={paginationModel}
       onPaginationModelChange={setPaginationModel}
       pagination
       onFilterModelChange={(filter) => {
         setWhere(convertDataGridFilterToGraphQLWhere(filter, columns));
       }}
       columns={columns}
       rowCount={(data || previousData)?.productFamiliesAggregate.count || 0}
       rows={(data || previousData)?.productFamilies || []}
       />
    </Box>
  );
}
