import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {
  BrowseChangeOrderIssuesQuery,
  ChangeOrderIssueWhere,
  SortDirection,
  useBrowseChangeOrderIssuesQuery,
} from '../../../../../generated/graphql';
import ErrorMessage from '../../../../common/ErrorMessage';
import { convertDataGridFilterToGraphQLWhere } from '../../../../../util/dataGridFilter';
import {
  aggregateColumn,
  dateTimeColumn, stringColumn, subFieldColumn, weekColumn,
} from '../../../../../util/dataGridColumns';
import { ChangeOrderIssueDialogButton } from './ChangeOrderIssueDialogButton';
import { colors } from '../../../../../services/mui-theme';

type Row = BrowseChangeOrderIssuesQuery['changeOrderIssues'][0];

const columns: GridColDef<Row>[] = [
  {
    field: 'actions',
    type: 'actions',
    width: 50,
    renderCell: (params) => <ChangeOrderIssueDialogButton id={params.row.id} />,
  },
  {
    ...stringColumn('coiNumber'),
    headerName: 'COI Number',
    width: 170,
  },
  {
    ...stringColumn('issue'),
    headerName: 'Issue',
    width: 120,
  },
  {
    ...weekColumn('introductionWeek'),
    headerName: 'IntroductionWeek',
  },
  {
    ...aggregateColumn('partVersionsAggregate'),
    headerName: 'PV',
    width: 80,
    renderCell: (params) => <NumberAdded value={params.value} />,
  },
  {
    ...aggregateColumn('partUsageAddsAggregate'),
    headerName: 'PU Adds',
    width: 80,
    renderCell: (params) => <NumberAdded value={params.value} />,
  },
  {
    ...aggregateColumn('partUsageDelsAggregate'),
    headerName: 'PU Dels',
    width: 80,
    renderCell: (params) => <NumberDeleted value={params.value} />,
  },
  {
    ...aggregateColumn('ConsumptionRecordAddsAggregate'),
    headerName: 'CR Adds',
    width: 80,
    renderCell: (params) => <NumberAdded value={params.value} />,
  },
  {
    ...aggregateColumn('ConsumptionRecordDelsAggregate'),
    headerName: 'CR Dels',
    width: 80,
    renderCell: (params) => <NumberDeleted value={params.value} />,
  },
  {
    ...aggregateColumn('PartConstituentAddsAggregate'),
    headerName: 'PC Adds',
    width: 80,
    renderCell: (params) => <NumberAdded value={params.value} />,
  },
  {
    ...aggregateColumn('PartConstituentDelsAggregate'),
    headerName: 'PC Dels',
    width: 80,
    renderCell: (params) => <NumberDeleted value={params.value} />,
  },
  {
    ...subFieldColumn('changeOrderIssue.name'),
    headerName: 'CO Name',
    valueGetter: (v) => v.row.changeOrder?.name,
    width: 360,
  },
  {
    ...dateTimeColumn('createdAt'),
    headerName: 'CreatedAt',
  },
  {
    ...dateTimeColumn('lastModified'),
    headerName: 'LastModified',
  },
];

export function ChangeOrderIssuesTable() {
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 100 });
  const [where, setWhere] = useState<ChangeOrderIssueWhere>({});
  const {
    data, error, loading, previousData,
  } = useBrowseChangeOrderIssuesQuery({
    variables: {
      where,
      options: {
        limit: paginationModel.pageSize,
        offset: paginationModel.page * paginationModel.pageSize,
        sort: [{ lastModified: SortDirection.Desc }],
      },
    },
  });

  return (
    <Box mt={2} display="flex" flexDirection="column" height="100%" component={Paper}>
      <ErrorMessage error={error}></ErrorMessage>
      <DataGrid
        density="compact"
        paginationMode="server"
        filterMode="server"
        loading={loading}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pagination
        // disableColumnMenu
        onFilterModelChange={(filter) => {
          setWhere(convertDataGridFilterToGraphQLWhere(filter, columns));
        }}
        pageSizeOptions={[]}
        columns={columns}
        rowCount={(data || previousData)?.changeOrderIssuesAggregate.count || 0}
        rows={(data || previousData)?.changeOrderIssues || []}
      />
    </Box>
  );
}

function NumberDeleted({ value }: { value: string | number }) {
  if (value === 0) {
    return <Typography variant="inherit" color={colors.brand.gray4}>-</Typography>;
  }

  return <Typography variant="inherit" color="error.dark">{value}</Typography>;
}

function NumberAdded({ value }: { value: string | number }) {
  if (value === 0) {
    return <Typography variant="inherit" color={colors.brand.gray4}>-</Typography>;
  }

  return <Typography variant="inherit" color="info.dark">{value}</Typography>;
}
