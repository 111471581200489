import React from 'react';
import {
  Box,
  List,
  Paper,
  Typography,
} from '@mui/material';
import { MfgChangeOrderIssueDialogQuery } from '../../../../../generated/graphql';
import { FieldListValue } from '../common/FieldListValue';
import { MfgCoiAssemblyPlaceholderTable } from './mfg-coi-dialog/MfgCoiAssemblyPlaceholderTable';
import { MfgCoiBopCompoundOperationTable } from './mfg-coi-dialog/MfgCoiBopCompoundOperationTable';
import { MfgCoiBopMfgAssemblyTargetTable } from './mfg-coi-dialog/MfgCoiBopMfgAssemblyTargetTable';
import { MfgCoiBopOperationTable } from './mfg-coi-dialog/MfgCoiBopOperationTable';
import { MfgCoiBopPartUsageTable } from './mfg-coi-dialog/MfgCoiBopPartUsageTable';
import { MfgCoiBopRoutingTable } from './mfg-coi-dialog/MfgCoiBopRoutingTable';
import { MfgCoiPartUsageTable } from './mfg-coi-dialog/MfgCoiPartUsageTable';

type MfgChangeOrderIssue = MfgChangeOrderIssueDialogQuery['mfgChangeOrderIssues'][0];

interface Props {
  mfgChangeOrderIssue?: MfgChangeOrderIssue | null
}

export function MfgChangeOrderIssueInfoPanel(props: Props) {
  const { mfgChangeOrderIssue } = props;

  if (!mfgChangeOrderIssue) {
    return null;
  }

  return (
    <>
      <Box p={2} mb={4}>
        <Typography variant="h3" mb={1} mx={2}>Mfg Change Order Issue</Typography>
        <Paper variant="outlined">
          <List disablePadding>
            <FieldListValue name="coiNumber" value={mfgChangeOrderIssue?.coiNumber} />
            <FieldListValue name="introduction" value={mfgChangeOrderIssue?.introduction} />
            <FieldListValue name="createdAt" value={mfgChangeOrderIssue?.createdAt} />
            <FieldListValue name="sapPlantCodes" value={mfgChangeOrderIssue?.sapPlantCodes} />
            <FieldListValue name="endItems" value={mfgChangeOrderIssue?.endItems} />
          </List>
        </Paper>
      </Box>

      <Box p={2} mb={4}>
        <Typography variant="h3" mb={1} mx={2}>Mfg Change Order</Typography>
        <Paper variant="outlined">
          <List disablePadding>
            <FieldListValue name="coNumber" value={mfgChangeOrderIssue?.changeOrder?.coNumber} />
            <FieldListValue name="name" value={mfgChangeOrderIssue?.changeOrder?.name} />
            <FieldListValue name="description" value={mfgChangeOrderIssue?.changeOrder?.description} />
            <FieldListValue name="createdAt" value={mfgChangeOrderIssue?.changeOrder?.createdAt} />
          </List>
        </Paper>
      </Box>
      <Box p={2} mb={4}>
        <Typography variant="h3" mb={1} mx={2}>
          Mfg Part Usages
        </Typography>
        <MfgCoiPartUsageTable
          rows={mfgChangeOrderIssue?.partUsageChanges || []} />
      </Box>

      <Box p={2} mb={4}>
        <Typography variant="h3" mb={1} mx={2}>
          Mfg AssemblyPlaceholder
        </Typography>
        <MfgCoiAssemblyPlaceholderTable
          rows={mfgChangeOrderIssue?.assemblyPlaceholderChanges || []} />
      </Box>

      <Box p={2} mb={4}>
        <Typography variant="h3" mb={1} mx={2}>
          BOP Mfg Assembly Target
        </Typography>
        <MfgCoiBopMfgAssemblyTargetTable
          rows={mfgChangeOrderIssue?.bopMfgAssemblyTargetChanges || []} />
      </Box>

      <Box p={2} mb={4}>
        <Typography variant="h3" mb={1} mx={2}>
          Bop Routing
        </Typography>
        <MfgCoiBopRoutingTable
          rows={mfgChangeOrderIssue?.bopRoutingChanges || []} />
      </Box>

      <Box p={2} mb={4}>
        <Typography variant="h3" mb={1} mx={2}>
          BOP Mfg Assembly Target
        </Typography>
        <MfgCoiBopMfgAssemblyTargetTable
          rows={mfgChangeOrderIssue?.bopMfgAssemblyTargetChanges || []} />
      </Box>

      <Box p={2} mb={4}>
        <Typography variant="h3" mb={1} mx={2}>
          Bop Compound Operation
        </Typography>
        <MfgCoiBopCompoundOperationTable
          rows={mfgChangeOrderIssue?.bopCompoundOperationChanges || []} />
      </Box>

      <Box p={2} mb={4}>
        <Typography variant="h3" mb={1} mx={2}>
          Bop Operation
        </Typography>
        <MfgCoiBopOperationTable
          rows={mfgChangeOrderIssue?.bopOperationChanges || []} />
      </Box>

      <Box p={2} mb={4}>
        <Typography variant="h3" mb={1} mx={2}>
          Bop Part Usages
        </Typography>
        <MfgCoiBopPartUsageTable
          rows={mfgChangeOrderIssue.bopPartUsageChanges || []} />
      </Box>
    </>
  );
}
