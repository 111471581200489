import React, { PropsWithChildren } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';

// https://prins-prod.azurewebsites.net/
// https://prins-qa.azurewebsites.net/

export function EnvironmentsPage() {
  return (
    <>
      <h1>Environments and Networking</h1>
      <Typography paragraph mb={4}>
        PRINS GraphHub is deployed on different environments for QA & Prod <br/>
        Access to both environments can be acquired through the GraphHub Control Panel
        but you need to request access to them separately.
      </Typography>

      <Environment>
        <Typography variant="h2" mb={2}>Production</Typography>
        <Item name='API Address'>https://prins-prod.azurewebsites.net/graphql</Item>
        <Item name='Port'>443</Item>
        <Item name={'Data source'}>
          <ListWithoutMargins>
            <li>TCPLM PROD</li>
            <li>Ace Noak Prod</li>
            <li>Como Prod</li>
          </ListWithoutMargins>
          <Typography mb={1}>
            <small>
            * See ”<Link href="/documentation/data-sources">Data sources</Link>” page for more info on what is imported from each source.
            </small>
          </Typography>
        </Item>
        <Item name={'IP address'}>
          <Typography paragraph>
            Current public IP <b>20.50.2.65</b>,
            but will change to an address <br/>
            in the private range <b>10.48.26.128 - 10.48.26.159</b> (CIDR: 10.48.26.128/27)
            in the future. If you have a firewall limiting data out then add both
            the public IP and the private IP range.
          </Typography>
        </Item>
      </Environment>
      <Environment>
        <Typography variant="h2" mb={2}>QA</Typography>
        <Item name='API Address'>https://prins-qa.azurewebsites.net/graphql</Item>
        <Item name='Port'>443</Item>
        <Item name={'Data Sources'}>
          <ListWithoutMargins>
            <li>TCPLM QA</li>
            <li>Ace Noak QA</li>
            <li>Como Prod</li>
          </ListWithoutMargins>
        </Item>
        <Item name={'IP address'}>
          <Typography paragraph>
            Ensure that a firewall is set up to allow outgoing
            data within these private IP address ranges.
            <ListWithoutMargins>
              <li> <b>10.48.26.0 to 10.48.26.127</b> (CIDR: 10.48.26.0/25)</li >
              <li> <b>10.48.171.0 to 10.48.171.255</b> (CIDR: 10.48.171.0/24)</li>
            </ListWithoutMargins>
          </Typography>
        </Item>
      </Environment>
      <Environment>
        <Typography variant="h2" mb={2}>Pre-production</Typography>
        <Item name='API Address'>https://prins-qa-preprod.azurewebsites.net/graphql</Item>
        <Item name='Port'>443</Item>
        <Item name={'Data Sources'}>
          <ListWithoutMargins>
            <li>
              Copied from PRINS GraphHub Production every night.
            </li>
          </ListWithoutMargins>
        </Item>
        <Item name={'IP address'}>
          Ensure that a firewall is set up to allow outgoing
          data within these private IP address ranges.
          <Typography paragraph>
            <ListWithoutMargins>
              <li> <b>10.48.26.0 to 10.48.26.127</b> (CIDR: 10.48.26.0/25)</li >
              <li> <b>10.48.171.0 to 10.48.171.255</b> (CIDR: 10.48.171.0/24)</li>
            </ListWithoutMargins>
          </Typography>
        </Item>
      </Environment>
      <Environment>
        <Typography variant="h2" mb={2}>Development</Typography>
        <Item name='API Address'>https://as-prins-graphhub-4497-dev.azurewebsites.net/graphql</Item>
        <Item name='Port'>443</Item>
        <Item name={'Data source'}>
          <ListWithoutMargins>
            <li>TCPLM DEV</li>
          </ListWithoutMargins>
        </Item>
        <Item name={'IP address'}>
          <Typography paragraph>
            Ensure that a firewall is set up to allow outgoing
            data within these private IP address ranges.
            <ListWithoutMargins>
              <li> <b>10.48.26.0 to 10.48.26.127</b> (CIDR: 10.48.26.0/25)</li >
              <li> <b>10.48.171.0 to 10.48.171.255</b> (CIDR: 10.48.171.0/24)</li>
            </ListWithoutMargins>
          </Typography>
        </Item>
      </Environment>
      <Environment>
        <Typography variant="h2" mb={2}>PLMNR</Typography>
        <Item name='API Address'>https://as-prins-graphhub-4497-plmnr.azurewebsites.net/graphql</Item>
        <Item name='Port'>443</Item>
        <Item name={'Data source'}>
          <ListWithoutMargins>
            <li>TCPLM NR</li>
          </ListWithoutMargins>
        </Item>
        <Item name={'IP address'}>
          <Typography paragraph>
            Ensure that a firewall is set up to allow outgoing
            data within the these private IP address ranges.
            <ListWithoutMargins>
              <li> <b>10.48.26.0 to 10.48.26.127</b> (CIDR: 10.48.26.0/25)</li >
              <li> <b>10.48.171.0 to 10.48.171.255</b> (CIDR: 10.48.171.0/24)</li>
            </ListWithoutMargins>
          </Typography>
        </Item>
      </Environment>
      <Environment>
        <Typography variant="h2" mb={2}>Test</Typography>
        <Item name='API Address'>https://as-prins-graphhub-4497-test.azurewebsites.net/graphql</Item>
        <Item name='Port'>443</Item>
        <Item name={'Data source'}>
          <ListWithoutMargins>
            <li>TCPLM Test</li>
          </ListWithoutMargins>
        </Item>
        <Item name={'IP address'}>
          <Typography paragraph>
            Ensure that a firewall is set up to allow outgoing
            data within the these private IP address ranges.
            <ListWithoutMargins>
              <li> <b>10.48.26.0 to 10.48.26.127</b> (CIDR: 10.48.26.0/25)</li >
              <li> <b>10.48.171.0 to 10.48.171.255</b> (CIDR: 10.48.171.0/24)</li>
            </ListWithoutMargins>
          </Typography>
        </Item>
      </Environment>
    </>
  );
}

function Environment(props: PropsWithChildren) {
  return (
    <Box mb={3} maxWidth={960} fontSize={16} >
      <Card variant="outlined">
        <CardContent>
          {props.children}
        </CardContent>
      </Card>
    </Box>
  );
}

function Item(props: PropsWithChildren<{ name: string }>) {
  return (
    <Box display="flex" sx={{
      display: {
        xs: 'block',
        sm: 'flex',
      },
    }}>
      <Box sx={{
        flexBasis: '150px',
        flexGrow: 0,
        flexShrink: 0,
        marginBottom: 0,
      }}>
        <Typography variant="subtitle2">
          {props.name}
        </Typography>
      </Box>
      <Box width="auto" flexGrow={1}>
        {props.children}
      </Box>
    </Box>
  );
}

function ListWithoutMargins(props: PropsWithChildren) {
  return (
    <Box sx={{
      '& > ul': {
        margin: '0 0 10px',
        paddingLeft: 2,
      },
    }}>
      <ul>
        {props.children}
      </ul>
    </Box>
  );
}
