import { createTheme } from '@mui/material';
import { responsiveFontSizes } from '@mui/material/styles';
import { typography } from './theme/typography';
// import { muiTheme } from '../../config/theme/theme';

// https://www.volvogroup.com/en/about-us/volvo-brand-identity/colors.html

export const colors = {
  primary: '#284E80', // CTA
  lightUi: {
    dark1: '#141414',
    dark2: '#707070',
    blue: '#1C6BBA', // accent
    red: '#BF2012',
    light1: '#D5D5D5',
    light2: '#EBEBEB',
    light3: '#FFFFFF',
    light4: '#FAFAFA',
  },

  brand: {
    blue1: '#202A44',
    blue2: '#1B365D',
    blue3: '#7089AC',
    blue4: '#CED9E5',

    gray1: '#53565A',
    gray2: '#888B8D',
    gray3: '#A7A8A9',
    gray4: '#C8C9C7',
    gray5: '#E1DFDD',

    black1: '#000000',
    black2: '#141414',
    white1: '#FFFFFF',

    orange1: '#A85F02',
    orange2: '#BF834B',
    orange3: '#D9B48B',
    orange4: '#F0DFC6',

    red1: '#622128',
    red2: '#9E2A2B',
    red3: '#DDA69D',
    red4: '#F0DDD7',

    green1: '#22372B',
    green2: '#476D3B',
    green3: '#A3B2A4',
    green4: '#E0E7D9',
  },
};

const muiTheme = createTheme({
  spacing: 8,
  palette: {
    background: {
      default: '#fff',
    },
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.lightUi.blue,
    },
    success: {
      main: colors.brand.green2,
    },
    warning: {
      main: colors.brand.orange2,
    },
    info: {
      light: colors.lightUi.blue, // used by alerts
      main: colors.brand.blue3,
    },
    error: {
      main: colors.lightUi.red,
    },
  },
  shape: {
    borderRadius: 0,
  },
  typography,
  components: {
    // MuiCardContent: {
    //   styleOverrides: {
    //     root: {
    //       padding: 24, // Same as tab panel
    //     },
    //   },
    // },
    // Align card actions to the right
    MuiCardActions: {
      styleOverrides: {
        root: {
          justifyContent: 'flex-end',
          padding: 16, // Same as card content
          paddingTop: 0,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: colors.brand.gray1,
          background: colors.brand.gray5,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 600,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            margin: '8px 0',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: colors.lightUi.light4,
        },
      },
    },
    MuiButton: {
      // Trying to look like the button example found here:
      // https://design.volvocars.com/type/typefaces/
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
  },
});

// declare module '@mui/material/styles' {
//   interface Theme {
//     status: {
//       danger: string;
//     };
//   }
//   // allow configuration using `createTheme`
//   interface ThemeOptions {
//     status?: {
//       danger?: string;
//     };
//   }
// }

export const theme = responsiveFontSizes(muiTheme);

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
