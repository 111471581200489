import {
  Box,
  CircularProgress,
} from '@mui/material';
import React from 'react';

interface Props {
  visible: boolean,
  text?: string,
  py?: number,
}

export function LoadingCircularCentered(props: Props) {
  const { visible, py } = props;

  if (!visible) {
    return null;
  }

  return (
    <Box py={py || 1} textAlign="center">
        <CircularProgress/>
        <div>{props.text}</div>
    </Box>
  );
}
